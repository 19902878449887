import {Partner} from './partner';
import {Base} from './base';

export class Partners extends Base {
  partnersEntryTitle: string = '';
  partnersBlockTitle: string = '';
  partnersListing: Partner[] = [];

  constructor(data = null) {
    super(data);
    this.partnersListing = this.dataHasKey(data, 'partnersListing')
      ? data['partnersListing'].map(item => new Partner(item))
      : [];
    this.partnersEntryTitle = this.propertyIsValid(data, 'partnersEntryTitle') ? data.partnersEntryTitle : '';
    this.partnersBlockTitle = this.propertyIsValid(data, 'partnersBlockTitle') ? data.partnersBlockTitle : '';
  }
}
