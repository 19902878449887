import {NgModule} from '@angular/core';
import {HeaderComponent} from './header/header.component';
import {HeaderDefaultComponent} from './header/header-default/header-default.component';
import {HeaderAgentComponent} from './header/header-agent/header-agent.component';
import {HeaderConsumerComponent} from './header/header-consumer/header-consumer.component';
import {FooterComponent} from './footer/footer.component';
import {MenuComponent} from './menu/menu.component';
import {FormsModule} from '@angular/forms';
import {CustomMaterialModule} from '../custom-material/custom-material.module';
import {CommonModule} from '@angular/common';
import {ContentService} from './content.service';
import {IsContentService} from './is-content.service';
import {NotFoundComponent} from './not-found/not-found.component';
import {CtaComponent} from './cta/cta.component';
import {RouterModule} from '@angular/router';
import {PipesModule} from '../pipes/pipes.module';
import {LinkService} from './link.service';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {DialogsModule} from '../dialogs/dialogs.module';
import {ApiServicesModule} from '../api-services/api-services.module';
import {MarkdownModule} from '../markdown/markdown.module';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { BannerComponent } from './banner/banner.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CustomMaterialModule,
    RouterModule,
    PipesModule,
    LazyLoadImageModule,
    DialogsModule,
    ApiServicesModule,
    MarkdownModule,
  ],
  declarations: [
    HeaderComponent,
    HeaderDefaultComponent,
    HeaderAgentComponent,
    HeaderConsumerComponent,
    FooterComponent,
    MenuComponent,
    NotFoundComponent,
    CtaComponent,
    MaintenanceComponent,
    BannerComponent,
  ],
  providers: [ContentService, IsContentService, LinkService],
  exports: [
    HeaderComponent,
    HeaderDefaultComponent,
    HeaderAgentComponent,
    HeaderConsumerComponent,
    FooterComponent,
    MenuComponent,
    NotFoundComponent,
    CtaComponent,
    BannerComponent,
  ],
})
export class CriticalContentModule {
}
