import {Menu} from './menu';
import {Partners} from './partners';
import {Base} from './base';

export class Footer extends Base {
  footerEntryTitle: string = '';
  footerPartners: Partners = new Partners();
  footerMenus: Menu[] = [];
  footerFormShortCode: string = '';
  socialNetworks: Menu = new Menu();
  footerStrapline: string = '';

  constructor(data = null) {
    super(data);
    this.footerMenus = this.dataHasKey(data, 'footerMenus') ? data['footerMenus'].map(item => new Menu(item)) : [];
    this.footerEntryTitle = this.propertyIsValid(data, 'footerEntryTitle') ? data.footerEntryTitle : '';
    this.footerPartners = this.propertyIsValid(data, 'footerPartners', 'object')
      ? new Partners(data.footerPartners)
      : new Partners();
    this.footerFormShortCode = this.propertyIsValid(data, 'footerFormShortCode') ? data.footerFormShortCode : '';
    this.socialNetworks = this.propertyIsValid(data, 'socialNetworks', 'object')
      ? new Menu(data.socialNetworks)
      : new Menu();
    this.footerStrapline = this.propertyIsValid(data, 'footerStrapline') ? data.footerStrapline : '';
  }
}
