import {AuthenticationService} from '../../auth/authentication.service';
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Header} from '../../models/content/header';
import {IsContentService} from '../is-content.service';
import {LinkService} from '../link.service';
import {MenuItem} from '../../models/content/menu-item';
import {NavigationStart, Router} from '@angular/router';
import {User} from '../../models/user';
import * as json from '../../../assets/content/ids/5lAOg9WAmsOs8Gg6qoSYGe.json';

@Component({
  selector: 'vmc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() onContactUs = new EventEmitter();

  public cache = false;
  public isConsumer: boolean = false;

  public canManageBranches: boolean = true;
  canManageClients: boolean = false;
  canManageClientUsers: boolean = false;
  canViewReportsPage: boolean = false;
  canViewDashboard: boolean = true;
  public content: Header;
  public currentUser: User = new User();
  public home_link = '/listings';
  public openUserMenu = false;
  public showBrochure = false;
  @Output() public openMenuEmitter = new EventEmitter();
  public isMenuOpened: boolean = false;
  canManageClientContacts = false
  isClientUserHome = false

  public isCompanyConveyancer: boolean = false;
  public isIndividualConveyancer: boolean = false;
  public isIndividualConsumer: boolean = false;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private isBrochure: IsContentService,
    private linkService: LinkService,
  ) {
  }

  ngOnInit(): void {
    document.body.addEventListener('mouseup', event => (this.openUserMenu = false), true);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.isMenuOpened) {
          this.toggleMenu();
        }
        this.auth.can('client-read').subscribe(response => (this.canManageClients = response));
        this.auth.can('client-contact-read').subscribe(response => this.canManageClientContacts = response);
        this.auth.can('client-user-read').subscribe(response => (this.canManageClientUsers = response));
        this.auth.can('report-view-connells').subscribe(response => (this.canViewReportsPage = response));
        this.auth.hasRole('Connells-role').subscribe(response => (this.canViewDashboard = (response != true)));

        this.auth.currentUser().subscribe(user => {
          this.currentUser = user || new User();
          this.isConsumer = this.currentUser.attributes.type.toLowerCase() == 'consumer';
          this.isCompanyConveyancer = this.currentUser.attributes.type.toLowerCase() == 'company conveyancer';
          this.isIndividualConveyancer = this.currentUser.attributes.type.toLowerCase() == 'individual conveyancer';
          this.isIndividualConsumer = this.currentUser.attributes.type.toLowerCase() == 'individual consumer';
        });

        this.showBrochure = false;
        this.home_link = this.auth.isLoggedIn() ? '/listings' : '/';
        this.isClientUserHome = event.url == '/users/home'
      }
    });

    // this.isBrochure.isContent$.subscribe(response => (this.showBrochure = response));
    this.home_link = this.auth.isLoggedIn() ? '/listings' : '/';
    this.getMenuContent();
  }

  ngOnDestroy() {
    document.body.removeEventListener('mouseup', event => (this.openUserMenu = false), true);
  }

  getMenuContent() {
    this.content = new Header(json['default']);
    this.content.headerMainMenu.menuItems = (this.content.headerMainMenu.menuItems as MenuItem[]).map(item =>
      this.linkService.processMenuItem(item, 'button is-white'),
    );
  }

  toggleMenu() {
    this.isMenuOpened = !this.isMenuOpened;
    this.openMenuEmitter.emit(this.isMenuOpened);
    return this.isMenuOpened;
  }

  loggedIn() {
    return this.auth.isLoggedIn();
  }

  showContact() {
    this.onContactUs.emit();
  }
}
