import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, retryWhen} from 'rxjs/operators';
import {MilestoneTypeCollection} from '../models/milestone-type';

@Injectable()
export class MilestoneGroupBranchService extends ApiService {
  workflow(milestone_group_id, branch_id, listing_id, options = {}): Observable<MilestoneTypeCollection> {
    let url =
      this.url +
      'milestone_group/' +
      milestone_group_id +
      '/branch/' +
      branch_id +
      '/listing/' +
      listing_id +
      '/workflow';
    url = url + ApiService.httpBuildQuery(options);
    return this.http.get<MilestoneTypeCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('MilestoneGroupBranchService::workflow, url=' + url, <MilestoneTypeCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }
  getMilestoneTypes(milestoneGroupId, branchId, offerListingId, include) : Observable<MilestoneTypeCollection> {
    let url =
      this.url +
      'offers/milestone-types'
    url = url + ApiService.httpBuildQuery();
    const data = {
      milestone_group_id: milestoneGroupId,
      branch_id: branchId,
      offer_listing_id: offerListingId,
      include: include
    };
    return this.http.post<MilestoneTypeCollection>(url, data, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('MilestoneGroupBranchService::getMilestoneTypes, url=' + url, <MilestoneTypeCollection>{}, {
        url,
        method: 'POST',
        headers: this.getHeadersData(),
        data
      })),
    );

  }
}
