import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

import {Observable} from 'rxjs/internal/Observable';
import {catchError, retryWhen} from 'rxjs/operators';
import {UserCollection, UserItem} from '../models/user';
import {InvitationCollection} from '../models/invitation';
import {BranchCollection} from '../models/branch';
import {ContactItem} from '../models/contact';

@Injectable()
export class UserService extends ApiService {
  get(id: number, options = {}): Observable<UserItem> {
    const url = `${this.url}user/${id}` + ApiService.httpBuildQuery(options);
    return this.http.get<UserItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('UserService::get, url=' + url, <UserItem>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  contact(id: number, options = {}): Observable<ContactItem> {
    const url = `${this.url}user/${id}/relationships/contact` + ApiService.httpBuildQuery(options);
    return this.http.get<ContactItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('UserService::contact, url=' + url, <ContactItem>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  current(options = {}): Observable<UserItem> {
    const url = this.url + 'user/current' + ApiService.httpBuildQuery(options);
    return this.http.get<UserItem>(url, {headers: this.getHeaders(), withCredentials: true}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('UserService::current, url=' + url, <UserItem>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  updateCurrent(attributes, options = {}): Observable<UserItem> {
    const url = this.url + 'user/current' + ApiService.httpBuildQuery(options);
    return this.http.put<UserItem>(url, attributes, {headers: this.getHeaders()}).pipe(catchError(this.handleError('UserService::updateCurrent, url=' + url, <UserItem>{})));
  }

  getInvites(): Observable<InvitationCollection> {
    const url = this.url + 'settings/invitations/pending?includes=team';
    return this.http.get<InvitationCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('UserService::getInvites, url=' + url, <InvitationCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  branches(options = {}) {
    const url = this.url + 'user/branches' + ApiService.httpBuildQuery(options);
    return this.http.get<BranchCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('UserService::branches, url=' + url, <BranchCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  paginateByType(name: string, options = {}): Observable<UserCollection> {
    const url = this.url + 'users/type/' + name + ApiService.httpBuildQuery(options);
    return this.http.get<UserCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('UserService::paginateByType, url=' + url, <any>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }
  maintenance() {
    const url = this.url + 'maintenance';
    return this.http.get(url);
  }
}
