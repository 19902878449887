import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError, map, retryWhen} from 'rxjs/operators';
import {Address, AddressCollection, AddressItem} from '../models/address';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class AddressService extends ApiService {
  search(filters, options): Observable<Address[]> {
    const url = this.url + 'address/filter' + ApiService.httpBuildQuery(options);
    return this.http.post<AddressCollection>(url, filters, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(
        this.handleError<AddressCollection>(
          'AddressService::search, url=' + url + ', filters=' + JSON.stringify(filters),
          <AddressCollection>{},
          {
            url,
            method: 'POST',
            headers: this.getHeadersData(),
            data: filters,
          }
        ),
      ),
      map(response => response.data),
    );
  }

  create(data): Observable<AddressItem> {
    const url = this.url + 'address';
    return this.http.post<AddressItem>(url, data, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError<AddressItem>('AddressService::create, url=' + url + ', data=' + JSON.stringify(data)),
      ),
    );
  }

  get(id, options?): Observable<AddressItem> {
    const url = this.url + 'address/' + id + ApiService.httpBuildQuery(options);
    return this.http.get<AddressItem>(url, {headers: this.getHeaders()}).pipe(catchError(this.handleError<AddressItem>('AddressService::get, url=' + url, <AddressItem>{}, {
      url,
      method: 'GET',
      headers: this.getHeadersData(),
    })));
  }

  parent(id, options?): Observable<AddressItem> {
    const url = this.url + 'address/' + id + '/relationships/parent' + ApiService.httpBuildQuery(options);
    return this.http.get<AddressItem>(url, {headers: this.getHeaders()}).pipe(catchError(this.handleError<AddressItem>('AddressService::parent, url=' + url, <AddressItem>{}, {
      url,
      method: 'GET',
      headers: this.getHeadersData(),
    })));
  }
}
