import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AddressService} from '../../api-services/address.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ListingItem} from '../../models/listing';

@Component({
  selector: 'vmc-unable-to-address-match',
  templateUrl: './unable-to-address-match.component.html',
  styleUrls: ['./unable-to-address-match.component.scss']
})
export class UnableToAddressMatchComponent implements OnInit {
  public address_line_1: String = '';
  public address_line_2: String = '';
  public address_line_3: String = '';
  public address_line_4: String = '';
  // public post_town: String = '';
  public postcode: String = '';
  public full_address: String = '';

  public submitting: Boolean = false;

  public message: String = '';

  public formErrors = {
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    address_line_4: '',
    // post_town: '',
    postcode: '',
  };

  public validationMessages = {
    address_line_1: {
      required: 'Address line 1 is required.',
    },
    // post_town: {
    //   required: 'Town is required.',
    // },
    postcode: {
      required: 'Postcode is required.',
    },
  };

  public addressForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private addressService: AddressService,
    private dialog: MatDialogRef<UnableToAddressMatchComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
  }

  ngOnInit() {
    const listing: ListingItem = this.dialogData.listing;
    if (listing) {
      this.full_address = listing.data.attributes.full_address;
      const full_address = listing.data.attributes.full_address;
      const splitAddress = full_address.split(',');
      this.postcode = splitAddress[splitAddress.length - 1];
      this.address_line_1 = splitAddress.length > 1 ? splitAddress[0].trim() : '';
      this.address_line_2 = splitAddress.length > 2 ? splitAddress[1].trim() : '';
      this.address_line_3 = splitAddress.length > 3 ? splitAddress[2].trim() : '';
      this.address_line_4 = splitAddress.length > 4 ? splitAddress[3].trim() : '';
    }
    this.buildForm();
  }

  onValueChanged(data?: any) {
    if (!this.addressForm) {
      return;
    }

    const form = this.addressForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';

        const control = form.get(field);

        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];

          if (control.errors !== null) {
            for (const key in control.errors) {
              if (control.errors.hasOwnProperty(key)) {
                this.formErrors[field] += messages[key] + ' ';
              }
            }
          }
        }
      }
    }
  }

  submit() {
    this.submitting = true;
    const addressLine1 = this.addressForm.get('address_line_1').value;
    const addressLine2 = this.addressForm.get('address_line_2').value;
    const addressLine3 = this.addressForm.get('address_line_3').value;
    const addressLine4 = this.addressForm.get('address_line_4').value;
    // const postTown = this.addressForm.get('post_town').value;
    const postcode = this.addressForm.get('postcode').value;
    const full_address_arr = [];
    if (addressLine1) {
      full_address_arr.push(addressLine1)
    }
    if (addressLine2) {
      full_address_arr.push(addressLine2)
    }
    if (addressLine3) {
      full_address_arr.push(addressLine3)
    }
    if (addressLine4) {
      full_address_arr.push(addressLine4)
    }
    // if(postTown){
    //   full_address_arr.push(postTown)
    // }
    if (postcode) {
      full_address_arr.push(postcode)
    }
    const full_address = full_address_arr.join(', ')
    this.addressService.create({
      address_line_1: addressLine1 ? addressLine1 : null,
      address_line_2: addressLine2 ? addressLine2 : null,
      address_line_3: addressLine3 ? addressLine3 : null,
      address_line_4: addressLine4 ? addressLine4 : null,
      full_address: full_address,
      // post_town: postTown ? postTown : null,
      postcode: postcode ? postcode : null,
      is_parent: false,
    }).subscribe(
      response => {
        this.message = 'Address added';
        this.submitting = false;
        this.dialog.close(response);
      },
      error => {
        this.submitting = false;
        if (error.error.errors['address_line_1']) {
          this.formErrors.address_line_1 = error.error.errors['address_line_1'];
        }
        // if (error.error.errors['post_town']) {
        //   this.formErrors.post_town = error.error.errors['post_town'];
        // }
        if (error.error.errors['postcode']) {
          this.formErrors.postcode = error.error.errors['postcode'];
        }
        this.message = error.error.message || 'There was a problem creating your address';
      },
    );
  }

  cancel() {
    this.dialog.close();
  }

  close() {
    this.cancel();
  }

  private buildForm() {
    this.addressForm = this.formBuilder.group({
      address_line_1: [this.address_line_1, Validators.required],
      address_line_2: [this.address_line_2],
      address_line_3: [this.address_line_3],
      address_line_4: [this.address_line_4],
      // post_town: [this.post_town],
      postcode: [this.postcode, Validators.required],
    });

    this.addressForm.valueChanges.subscribe(data => this.onValueChanged(data));

    this.onValueChanged();
  }

}
