import {Base} from './base';

export class Partner extends Base {
  partnerName: string = '';
  partnerImage: string = '';
  partnerUrl: string = '';

  constructor(data = null) {
    super(data);
    this.partnerName = this.propertyIsValid(data, 'partnerName') ? data.partnerName : '';
    this.partnerImage = this.propertyIsValid(data, 'partnerImage') ? data.partnerImage : '';
    this.partnerUrl = this.propertyIsValid(data, 'partnerUrl') ? data.partnerUrl : '';
  }
}
