import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import * as _moment from 'moment';
import {Offer} from 'src/app/models/offer';
import {OfferListing} from 'src/app/models/offer-listing';
import {AuthenticationService} from '../../auth/authentication.service';

const moment = _moment;

@Component({
  selector: 'vmc-alert',
  templateUrl: './chained-conflict.component.html',
  styleUrls: ['./chained-conflict.component.scss'],
})
export class ChainedConflictComponent implements OnInit {
  offer: Offer;
  errOfferListings: OfferListing;
  branchIds: number[];
  isAdmin: boolean;

  constructor(public dialogRef: MatDialogRef<ChainedConflictComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private authService: AuthenticationService) {
  }

  ngOnInit() {
    this.offer = this.data.offer;
    this.errOfferListings = this.data.errOfferListings;
    this.branchIds = this.authService.teams.map(team => team.attributes.branch_id);
    this.isAdmin = this.authService.user.attributes.type === 'admin';
  }

  buildMailTo(): string {
    const branchName = this.offer.attributes.listing.branch.display_name;
    const subject = 'Error: Chained Property Conflict sold by ' + branchName ;

    const displayAddress = this.offer.attributes.listing.display_address;
    const buyerName = this.offer.attributes.buyer_name;
    const offerDate = moment.unix(this.offer.attributes.offer_date).format('DD/MM/YYYY');
    const offerPrice = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(this.offer.attributes.offer);

    const body = 'Dear ViewMyChain,\n\n' +
      'I was trying to change an offer to Sold STC status and got an error message: “Chained Property Conflict”.\n' +
      'My property address is ' + displayAddress + ', and I would like to mark the offer from ' + buyerName + 
      ' as Sold STC with an offer date of ' + offerDate + ' and an offer price of £' + offerPrice + '. ' +
      'My branch details are: ' + branchName + '.\n' + 'I can confirm the chain I’ve built in the offer is correct, ' +
      'please help me sort this issue out.\n\n' +
      'Kind regards,';

    return `mailto:support@viewmychain.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }
}
