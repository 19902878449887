import {ApiResponse, Relation, Relations} from '../api-services/api-response';

export class ContactCollection extends ApiResponse {
  data: Contact[] = [];
}

export class ContactItem extends ApiResponse {
  data: Contact = new Contact();
}

export class ContactRelations {
  user: Relation;
  listings: Relations;
  reports: Relations;
}

export class Contact {
  id: number = null;
  type: string = '';
  attributes: ContactAttributes = new ContactAttributes();
  relationships?: ContactRelations;
}

export class ContactAttributes {
  user_id: number = null;
  title: string = '';
  first_name: string = '';
  last_name: string = '';
  email: string = '';
  telephone_number: string = '';
  mobile_number: string = '';
  dob: Date;
  funding_consent: boolean = false;
  marketing_consent: boolean = false;
  notification_type_id: number = null;
  role: string = '';
  created_at: number = null;
  updated_at: number = null;
  deleted_at: number = null;
}
