import {Component, Input, OnChanges, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'vmc-markdown',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./markdown.component.scss'],
  template: `
    <div [innerHTML]="convertedData | safe" [ngClass]="{ content: isContent }"></div>
  `,
})
export class MarkdownComponent implements OnChanges {
  static hubspotFormChecker: string = 'hbspt.forms.create';

  @Input()
  data: string;

  @Input()
  isContent: boolean = true;

  convertedData: string = '';

  scripts: string[] = [];

  // Predefined styles
  manualStyles = {
   '.standard__body' : {
     width: '100%',
   },
    '.section--standard > .container': {
      maxWidth: '66.66666667%',
    }
  };

  // Hubspot data
  hubspotFormContainer: string = '<div id="hubspot-form"></div>';

  ngOnChanges() {
    this.initData();

    if (!this.convertedData) { return; }

    this.initScripts();

    if (!this.scripts) { return; }

    this.runScripts();

    this.removeScripts();

    this.runStyles();
  }

  private initData(): void {
    const marker = require('showdown');

    marker.extension('responsive-table', function () {
      return [
        {
          type: 'output',
          filter: function (html, converter, options) {
            return html.replace(/<table>/g, '<div class="table-responsive"><table class="table is-striped">').replace(/<\/table>/g, '</table></div>');
          },
        },
      ];
    });

    const md = new marker.Converter({
      tables: true,
      strikethrough: true,
      extensions: ['responsive-table'],
    });

    md.setFlavor('github');

    this.convertedData = this.data ? md.makeHtml(this.data) : '';
  }

  private initScripts(): void {
    this.scripts = this.convertedData.match(/<script.*?>([\s\S]*?)<\/script>/gi);
  }

  private runScripts(): void {
    this.scripts.forEach(script => {
      let scriptContent = script.replace(/<script.*?>/g, '').replace(/<\/script>/g, '');

      if (scriptContent) {
        if (MarkdownComponent.checkIfHubspotForm(scriptContent)) {
          this.createHubspotContainer();

          scriptContent = MarkdownComponent.preHandleHubspotScript(scriptContent);
        }

        eval(scriptContent);
      }
    });
  }

  private removeScripts(): void {
    this.convertedData = this.convertedData.replace(/<script.*?>([\s\S]*?)<\/script>/gi, '');
  }

  private runStyles(): void {
    for (const [key, value] of Object.entries(this.manualStyles)) {
      const element = document.querySelector(key) as HTMLElement;

      if (element) {
        for (const [styleKey, styleValue] of Object.entries(value)) {
          element.style[styleKey] = styleValue;
        }
      }
    }
  }

  private createHubspotContainer(): void {
    this.convertedData += this.hubspotFormContainer;
  }

  private static checkIfHubspotForm(scriptContent): boolean {
    return scriptContent.indexOf(MarkdownComponent.hubspotFormChecker) > -1;
  }

  private static preHandleHubspotScript(scriptContent): string {
    const scriptContentArr = scriptContent.split('})');

    const len = scriptContentArr.length;

    scriptContentArr[len - 2] += ',target: "#hubspot-form"';

    return scriptContentArr.join('})');
  }
}
