import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, retryWhen} from 'rxjs/operators';
import {MilestoneChecklistCollection, MilestoneChecklistFilters} from '../models/milestone-checklist';

@Injectable()
export class MilestoneChecklistService extends ApiService {
  filter(filters: MilestoneChecklistFilters): Observable<MilestoneChecklistCollection> {
    const url = `${this.url}milestone_checklist/filter?includes=items`;
    return this.http.post<MilestoneChecklistCollection>(url, filters, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(
        this.handleError(
          'MilestoneChecklistService::filter, url=' + url + ', filters=' + JSON.stringify(filters),
          <MilestoneChecklistCollection>{},
          {
            url,
            method: 'POST',
            headers: this.getHeadersData(),
            data: filters,
          }
        ),
      ),
    );
  }
}
