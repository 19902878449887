import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, retryWhen} from 'rxjs/operators';
import {ReportCollection} from '../models/report';

@Injectable()
export class ReportService extends ApiService {
  all(options): Observable<ReportCollection> {
    const url = this.url + 'report' + ApiService.httpBuildQuery(options);
    return this.http.get<ReportCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ReportService::all, url=' + url, <ReportCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  getReport(data, options= {}): Observable<any> {
    const url = this.url + 'get-report' + ApiService.httpBuildQuery(options);
    return this.http.post<any>(url, data, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ReportService::all, url=' + url, <any>{}, {
        url,
        method: 'POST',
        headers: this.getHeadersData(),
      })),
    );
  }
}
