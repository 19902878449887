import './polyfills.ts'
import 'hammerjs'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { enableProdMode } from '@angular/core'
import { environment } from './environments/environment'
import { AppModule } from './app/app.module'

const enableGoogleTagManager = () => {
  const googleTagManagerSnippet = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+
    '&gtm_auth=${environment.gtm_auth}&gtm_preview=${environment.gtm_preview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${environment.gtm_container_id}');
  `
  document.write('<script type="text/javascript">' + googleTagManagerSnippet + '</script>')
}

if (environment.name !== 'development') {
  enableProdMode()
  // enableGoogleTagManager();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.register('/ngsw-worker.js')
    }
  })
  .catch(err => console.log(err))
