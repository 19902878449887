import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {AddressFinderModule} from '../../shared/address-finder/address-finder.module';
import {CommonModule} from '@angular/common';
import { AddAnAddressComponent } from './add-an-address.component';

@NgModule({
  imports: [
    MatDialogModule,
    AddressFinderModule,
    CommonModule
  ],
  declarations: [AddAnAddressComponent],
  entryComponents: [AddAnAddressComponent],
  providers: [],
  exports: [AddAnAddressComponent],
})
export class AddAnAddressComponentModule {
}
