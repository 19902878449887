import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class EchoInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const socketID = (<any>window).Echo.socketId();

    if (socketID) {
      return next.handle(
        req.clone({
          setHeaders: {
            'X-Socket-ID': socketID,
          },
        }),
      );
    }

    return next.handle(req);
  }
}
