import {Base} from './base';

export class Category extends Base {
  categoryTitle: string = '';
  categorySlug: string = '';
  categoryDescription: string = '';

  constructor(data = null) {
    super(data);
    this.categoryTitle = this.propertyIsValid(data, 'categoryTitle') ? data.categoryTitle : '';
    this.categorySlug = this.propertyIsValid(data, 'categorySlug') ? data.categorySlug : '';
    this.categoryDescription = this.propertyIsValid(data, 'categoryDescription') ? data.categoryDescription : '';
  }
}
