import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {CanDeactivate} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuardService implements CanDeactivate<CanComponentDeactivate> {
  constructor() {
  }

  canDeactivate(component: CanComponentDeactivate) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean>|Promise<boolean>|boolean
}
