import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AddressService} from '../../api-services/address.service';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'vmc-address-create',
  templateUrl: './address-create.component.html',
  styleUrls: ['./address-create.component.scss'],
})
export class AddressCreateComponent implements OnInit {
  public address_line_1: String = '';
  public post_town: String = '';
  public postcode: String = '';

  public submitting: Boolean = false;

  public message: String = '';

  public formErrors = {
    address_line_1: '',
    post_town: '',
    postcode: '',
  };

  public validationMessages = {
    address_line_1: {
      required: 'Address line 1 is required.',
    },
    post_town: {
      required: 'Town is required.',
    },
    postcode: {
      required: 'Postcode is required.',
    },
  };

  public addressForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private addressService: AddressService,
    private dialog: MatDialogRef<AddressCreateComponent>,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  onValueChanged(data?: any) {
    if (!this.addressForm) {
      return;
    }

    const form = this.addressForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';

        const control = form.get(field);

        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];

          if (control.errors !== null) {
            for (const key in control.errors) {
              if (control.errors.hasOwnProperty(key)) {
                this.formErrors[field] += messages[key] + ' ';
              }
            }
          }
        }
      }
    }
  }

  submit() {
    this.submitting = true;
    const addressLine1 = this.addressForm.get('address_line_1');
    const postTown = this.addressForm.get('post_town');
    const postcode = this.addressForm.get('postcode');
    this.addressService.create({
      address_line_1: addressLine1 ? addressLine1.value : null,
      post_town: postTown ? postTown.value : null,
      postcode: postcode ? postcode.value : null,
      is_parent: false,
    }).subscribe(
      response => {
        this.message = 'Address added';
        this.submitting = false;
        this.dialog.close(response);
      },
      error => {
        this.submitting = false;
        if (error.error.errors['address_line_1']) {
          this.formErrors.address_line_1 = error.error.errors['address_line_1'];
        }
        if (error.error.errors['post_town']) {
          this.formErrors.post_town = error.error.errors['post_town'];
        }
        if (error.error.errors['postcode']) {
          this.formErrors.postcode = error.error.errors['postcode'];
        }
        this.message = error.error.message || 'There was a problem creating your address';
      },
    );
  }

  cancel() {
    this.dialog.close();
  }

  close() {
    this.cancel();
  }

  private buildForm() {
    this.addressForm = this.formBuilder.group({
      address_line_1: [this.address_line_1, Validators.required],
      post_town: [this.post_town, Validators.required],
      postcode: [this.postcode, Validators.required],
    });

    this.addressForm.valueChanges.subscribe(data => this.onValueChanged(data));

    this.onValueChanged();
  }
}
