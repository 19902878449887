import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError, retryWhen} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {DelayTypeCollection} from '../models/delay-type';

@Injectable()
export class DelayTypeService extends ApiService {

  all(options = {}): Observable<DelayTypeCollection> {
    const url = this.url + 'delay-types' + ApiService.httpBuildQuery(options);
    return this.http.get<DelayTypeCollection>(url, {headers: this.getHeaders()})
      .pipe(
        retryWhen(this.retryOnSystemFailure),
        catchError(this.handleError('DelayTypeService::all, url=' + url, <DelayTypeCollection>{}, {
          url,
          method: 'GET',
          headers: this.getHeadersData(),
        }))
      );
  }
}
