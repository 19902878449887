import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, retryWhen} from 'rxjs/operators';
import {ApiService} from './api.service';
import {OutstandingItemItem} from '../models/outstanding-item';

@Injectable({
  providedIn: 'root',
})
export class OutstandingItemService extends ApiService {
  get(id): Observable<OutstandingItemItem> {
    const url = this.url + 'listing/' + id + '/outstanding-items';
    return this.http.get<OutstandingItemItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError<OutstandingItemItem>('OutstandingItemService::get, url=' + url, <OutstandingItemItem>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }
}
