import {Injectable} from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from 'src/environments/environment';
// import {IntercomService} from '../shared/intercom/intercom.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class LogOutService {
  constructor(
    private http: HttpClient,
    private cookieService: CookieService) {
  }

  handle(redirect = null) {
    if (environment.env !== 'dev') {
      return window.location.href = environment.vmc_webapp2_url + 'handle-logout';
    }

    /*if (environment.name !== 'development') {
      this.intercomService.shutdownAndReboot();
    }*/

    localStorage.clear();
    Sentry.configureScope(scope => {
      scope.setUser(null);
    });
    this.cookieService.delete('accessToken' + '-' + environment.env, '/', environment.share_cookies_domain);
    // this.cookieService.delete('isLogin', '/', '.viewmychain.com');
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = environment.vmc_api_url + 'logout';
    this.http.post(url, {headers: headers, withCredentials: true}).subscribe(
      response => {
        let loginUrl = '/login';
        if (redirect && redirect !== '/handle-logout') {
          loginUrl += '?redirectUrl=' + encodeURI(redirect);
        }
        window.location.href = loginUrl;
      },
      error => null,
    );
  }
}
