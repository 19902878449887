import {ExchangeReadyItem} from '../models/exchange-ready';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, retryWhen} from 'rxjs/operators';

@Injectable()
export class ExchangeReadyService extends ApiService {
  get(listingId): Observable<ExchangeReadyItem> {
    const url = this.url + 'listing/' + listingId + '/exchange-ready';
    return this.http.get<ExchangeReadyItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ExchangeReadyService::get, url=' + url, <ExchangeReadyItem>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }
}
