import {Injectable, Injector} from '@angular/core';
import {Observable, Subject, throwError} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {EchoService} from '../shared/echo.service';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  refreshTokenInProgress: boolean = false;
  refreshTokenHasFailed: boolean = false;

  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(private auth: AuthenticationService, private echo: EchoService, private injector: Injector) {
  }

  handle() {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.auth.refreshToken().pipe(
        tap(response => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
          this.echo.init();
        }),
      );
    }
  }

  error(error) {
    this.refreshTokenHasFailed = true;
    this.auth.redirectUrl = this.injector.get(Location).path();
    this.auth.logout();

    return throwError(error);
  }
}
