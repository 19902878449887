import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError, retryWhen} from 'rxjs/operators';
import {SimpleResponse} from './api-response';
import {Listing, ListingCollection} from '../models/listing';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class ChainService extends ApiService {
  check(chain, top: boolean): Observable<SimpleResponse> {
    const url = this.url + 'chain/' + chain + '/check';
    const data = {
      reason: 'Checked',
      top: top,
    };
    return this.http.post<SimpleResponse>(url, data, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError('ChainService::check, url=' + url + ', data=' + JSON.stringify(data), <SimpleResponse>{}),
      ),
    );
  }

  uncheck(chain, top: boolean): Observable<SimpleResponse> {
    const url = this.url + 'chain/' + chain + '/uncheck';
    const data = {
      reason: 'Not EOC',
      top: top,
    };
    return this.http.post<SimpleResponse>(url, data, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError('ChainService::uncheck, url=' + url + ', data=' + JSON.stringify(data), <SimpleResponse>{}),
      ),
    );
  }

  link(buyer, seller, options = {}): Observable<ListingCollection> {
    const url = this.url + 'chain/add/link' + ApiService.httpBuildQuery(options);
    const data = {
      buyer: buyer,
      seller: seller,
    };
    return this.http.post<ListingCollection>(url, data, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError('ChainService::link, url=' + url + ', data=' + JSON.stringify(data), <ListingCollection>{}),
      ),
    );
  }

  uncouple(buyer: Listing, seller: Listing): Observable<SimpleResponse> {
    const url = this.url + 'chain/remove/link';
    const data = {
      buyer: buyer.id,
      seller: seller.id,
    };
    return this.http.post<SimpleResponse>(url, data, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError('ChainService::uncouple, url=' + url + ', data=' + JSON.stringify(data), <SimpleResponse>{}),
      ),
    );
  }

  updateBrokenStatus(listingId: number, isBroken: boolean): Observable<SimpleResponse> {
    const url = this.url + `listing/chain/${listingId}/${isBroken ? 'break' : 'unbreak'}`;
    return this.http.put<SimpleResponse>(url, {}, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError('ChainService::markAsBrokenChain, url=' + url + ', data=' + JSON.stringify({}), <SimpleResponse>{}),
      ),
    );
  }

  listings(chain_link_id, options = {}): Observable<ListingCollection> {
    const url = this.url + 'chain/' + chain_link_id + '/listings' + ApiService.httpBuildQuery(options);
    return this.http.get<ListingCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ChainService::listings, url=' + url, <ListingCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  uncoupleOfferListing(buyerId, sellerId, offerId): Observable<SimpleResponse> {
    const url = this.url + 'offers/chain/remove/link';
    const data = {
      buyer_id: buyerId,
      seller_id: sellerId,
      offer_id: offerId
    };
    return this.http.post<SimpleResponse>(url, data, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError('ChainService::uncouple, url=' + url + ', data=' + JSON.stringify(data), <SimpleResponse>{}),
      ),
    );
  }

  splitChainBreak(listingId: string | number, nextListingId: string | number) {
    const url = this.url + `chain/${listingId}/break/${nextListingId}`
    const response =  this.http.post<SimpleResponse>(url, {}, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError('ChainService::uncouple, url=' + url + ', data=' + JSON.stringify({}), <SimpleResponse>{}),
      ),
    );
    return response
  }
}
