import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListingItem} from '../../models/listing';

@Component({
  selector: 'vmc-address-finder',
  templateUrl: './address-finder.component.html',
  styleUrls: ['./address-finder.component.scss'],
})
export class AddressFinderComponent implements OnInit {
  @Input()
  public defaultValue: string = '';

  @Input()
  public rows: Number = 99;

  @Output()
  public onSelect = new EventEmitter();
  @Output()
  public onClose = new EventEmitter();

  @Input()
  public displayCreateOption: Boolean = null;

  @Input()
  public id: string = 'default';

  @Input()
  public listing: ListingItem;

  showAdvanced: boolean = false;

  constructor() {
  }

  ngOnInit() {
  }

  select(value) {
    this.onSelect.emit(value);
  }
  close(value) {
    this.onClose.emit(value);
  }
}
