import {MatCheckboxChange} from '@angular/material/checkbox/typings/checkbox';
import {Contact} from '../../models/contact';
import {ConsumerSignUpContent} from '../../consumers/consumer-sign-up/content';
import {Component, EventEmitter, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ContactService} from '../../api-services/contact.service';
import * as json from '../../../assets/content/ids/4d6XUnuRg4KyYgiUguO2Ac.json';

@Component({
  selector: 'vmc-funding-consent',
  templateUrl: './funding-consent.component.html',
  styleUrls: ['./funding-consent.component.scss'],
})
export class FundingConsentComponent implements OnInit {
  public onSubmit = new EventEmitter();

  public errors: Array<string> = [];

  content = new ConsumerSignUpContent();

  @Input()
  contact: Contact = new Contact();

  public formProcessing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<FundingConsentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private contactService: ContactService,
  ) {
  }

  ngOnInit() {
    this.contact = this.data.contact;

    this.content = new ConsumerSignUpContent(json['default']);
  }

  giveFundingConsentClicked() {
    this.errors = [];
    this.onSubmit.emit(true);
  }

  doesNotGiveFundingConsentClicked() {
    this.errors = [];
    this.onSubmit.emit(false);
  }

  setFundingConsent(value: MatCheckboxChange) {
    this.toggleFormProcessing();
    this.contactService.update(this.contact.id, {funding_consent: value.checked}).subscribe(
      response => {
        this.contact = response.data;
        this.onSubmit.emit(this.contact);
        this.dialogRef.close();
      },
      errors => {
        this.errors = errors.error.errors;
        this.toggleFormProcessing();
      },
    );
  }

  public toggleFormProcessing() {
    this.formProcessing = !this.formProcessing;
  }

  public setError(errors) {
    this.errors = errors;
  }
}
