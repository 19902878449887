import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, retryWhen} from 'rxjs/operators';
import {MilestoneReasonCollection, MilestoneReasonItem} from '../models/milestone-reason';

@Injectable()
export class MilestoneReasonService extends ApiService {
  getReasons(options = {}): Observable<MilestoneReasonCollection> {
    const url = this.url + 'listing/milestone/reasons' + ApiService.httpBuildQuery(options);
    return this.http.get<MilestoneReasonCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('MilestoneReasonService::getReasons, url=' + url, <MilestoneReasonCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  getListingReason(listingId, options = {}): Observable<MilestoneReasonItem> {
    const url = this.url + 'listing/' + listingId + '/milestone-reason' + ApiService.httpBuildQuery(options);
    return this.http.get<MilestoneReasonItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('MilestoneReasonService::getListingReason, url=' + url, <MilestoneReasonItem>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  putListingReason(listingId, data): Observable<any> {
    const url = this.url + 'listing/' + listingId + '/milestone-reason';
    return this.http.put<any>(url, data, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('MilestoneReasonService::putListingReason, url=' + url, <any>{})),
    );
  }

  getOfferListingReason(offerListingId, options = {}): Observable<MilestoneReasonItem> {
    const url = this.url + 'offers/offer-listing/' + offerListingId + '/milestone-reason' + ApiService.httpBuildQuery(options);
    return this.http.get<MilestoneReasonItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('MilestoneReasonService::getListingReason, url=' + url, <MilestoneReasonItem>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  putOfferListingReason(offerListingId, data): Observable<any> {
    const url = this.url + 'offers/offer-listing/' + offerListingId + '/milestone-reason';
    return this.http.put<any>(url, data, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('MilestoneReasonService::putListingReason, url=' + url, <any>{})),
    );
  }
}
