import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'vmc-confirm-with-datepicker',
  templateUrl: './confirm-with-datepicker.component.html',
  styleUrls: ['./confirm-with-datepicker.component.scss'],
})
export class ConfirmWithDatepickerComponent implements OnInit {
  public onSubmit = new EventEmitter();

  public title: String = '';
  public body: String = 'Please enter the date';
  public submitButtonLabel: String = 'Submit';
  public cancelButtonLabel: String = 'Cancel';
  public errors: Array<string> = [];

  public day: number = new Date().getDate();
  public month: number = new Date().getMonth() + 1;
  public year: number = new Date().getFullYear();

  public years: Array<number> = [new Date().getFullYear() - 1, new Date().getFullYear(), new Date().getFullYear() + 1];
  public days: Array<number> = Array.from(Array(32).keys()).slice(1);

  public formProcessing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmWithDatepickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit() {
    if (this.data.title) {
      this.title = this.data.title;
    }

    if (this.data.body) {
      this.body = this.data.body;
    }

    if (this.data.submitButtonLabel) {
      this.submitButtonLabel = this.data.submitButtonLabel;
    }

    if (this.data.cancelButtonLabel) {
      this.cancelButtonLabel = this.data.cancelButtonLabel;
    }
  }

  onSubmitClicked() {
    this.errors = [];
    this.onSubmit.emit({
      timestamp: this.toDateTimeString(new Date(this.year, +this.month - 1, this.day)),
    });
  }

  public toggleFormProcessing() {
    this.formProcessing = !this.formProcessing;
  }

  public setError(errors) {
    this.errors = errors;
  }

  private toDateTimeString(date: Date) {
    let month = (date.getMonth() + 1).toString();
    month = month.length == 1 ? '0' + month : month;

    let day = date.getDate().toString();
    day = day.length == 1 ? '0' + day : day;

    return date.getFullYear() + '-' + month + '-' + day + ' 00:00:00';
  }
}
