import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
    selector: 'vmc-confirm',
    templateUrl: './confirm-break-chain.component.html',
    styleUrls: ['./confirm-break-chain.component.scss'],
  })
  export class ConfirmBreakChainComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<ConfirmBreakChainComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    public listing
    public nextListing
  
    ngOnInit() {
        this.listing = this.data.listing
        this.nextListing = this.data.nextListing
    }
    
  }