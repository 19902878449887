import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safe',
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any, trustType?: string) {
    let result;
    switch (trustType) {
      case 'url':
        result = this.sanitizer.bypassSecurityTrustResourceUrl(value);
        break;
      default:
        result = this.sanitizer.bypassSecurityTrustHtml(value);
        break;
    }
    return result;
  }
}
