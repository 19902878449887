import {Address} from '../../models/address';
import {AddressService} from '../../api-services/address.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';
import {map, startWith} from 'rxjs/operators';
import {AuthenticationService} from '../../auth/authentication.service';
import {MatDialog} from '@angular/material/dialog';
import {UnableToAddressMatchComponent} from '../../addresses/unable-to-address-match/unable-to-address-match.component';
import {ListingItem} from '../../models/listing';
import {Router} from '@angular/router';
import {ListingService} from '../../api-services/listing.service';

@Component({
  selector: 'vmc-address-lookup',
  templateUrl: './address-lookup.component.html',
  styleUrls: ['./address-lookup.component.scss'],
})
export class AddressLookupComponent implements OnInit {
  myControl = new FormControl();
  public addressForm: FormGroup;
  public postcode: string = '';

  public addresses: Address[] = [];
  public filteredOptions: Observable<Address[]>;

  public running: boolean = false;
  public searched: boolean = false;

  public unableToAddressMatchOptionLabel: String = 'Unable to address match';

  @Input()
  public displayCreateOption: Boolean = null;

  @Output()
  public onSelect = new EventEmitter();

  @Output()
  public onClose = new EventEmitter();

  @Input()
  public listing: ListingItem;

  @Input()
  id = 'default';

  @Input()
  public rows: Number = 99;

  constructor(
    private addressService: AddressService,
    private auth: AuthenticationService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private listingService: ListingService,
  ) {
  }

  ngOnInit() {
    if (this.displayCreateOption === null) {
      this.setDisplayCreateOption();
    }
    this.buildForm();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith<string|Address>(''),
      map(value => {
        if (value === null) {
          return;
        }

        if (typeof value === 'string') {
          return value;
        } else {
          return value.attributes.full_address;
        }
      }),
      map(name => (name ? this._filter(name) : this.addresses.slice())),
    );
  }

  buildForm() {
    this.addressForm = this.formBuilder.group({
      homeNumber: [''],
      postcode: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(8)]),
    });
  }

  submit() {
    const homeNumber = this.addressForm.controls.homeNumber.value;
    this.postcode = this.addressForm.controls.postcode.value;

    const searchTerm = homeNumber + ' ' + this.postcode;
    this.running = true;

    this.addresses = [];
    this.myControl.reset();

    this.addressService.search({full_address: searchTerm}, {rows: this.rows}).subscribe(
      response => {
        this.running = false;
        this.searched = true;
        this.addresses = response;
      },
      error => {
        this.searched = true;
        this.running = false;
      },
    );
  }

  select(address) {
    if (address.option.viewValue !== this.unableToAddressMatchOptionLabel) {
      this.onSelect.emit(address.option.value);
      this.running = false;
      return;
    }

    if (this.listing) {
      if (this.listing.data.attributes.land_new_home) {
        const dialogRef = this.dialog.open(UnableToAddressMatchComponent, {
          width: '800px',
          data: {
            listing: this.listing
          }
        });
        dialogRef.afterClosed().subscribe(response => {
          if (response) {
            this.onSelect.emit(response['data']);
          }
        });
      } else {
        this.listingService.update(this.listing.data, {
          verified_as_property: -1
        }).subscribe(
          response => {
            this.onClose.emit();
            if (response.data.id) {
              this.router.navigate([`listing`]);
            }
          },
        );
      }
    }
  }

  displayFn(address?: Address): string|undefined {
    return address ? address.attributes.full_address : undefined;
  }

  private _filter(full_address: string): Address[] {
    const filterValue = full_address.toLowerCase();
    return this.addresses.filter(address => address.attributes.full_address.toLowerCase().indexOf(filterValue) === 0);
  }

  private setDisplayCreateOption() {
    this.auth.can('address-create-integrator').subscribe(response => (this.displayCreateOption = response)); //
  }

}
