import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError} from 'rxjs/operators';
import {InvitationItem} from '../models/invitation';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class InvitationService extends ApiService {
  create(data): Observable<InvitationItem> {
    const url = this.url + 'invitation';
    return this.http.post<InvitationItem>(url, data, {headers: this.getHeaders()}).pipe(catchError(this.handleError('InvitationService::create, url=' + url, <InvitationItem>{})));
  }
}
