import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {Subject} from 'rxjs/internal/Subject';
import {catchError, first, map, switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {UserService} from '../api-services/user.service';
import {Permission} from '../models/permission';
import {Team} from '../models/team';
import {User, UserItem} from '../models/user';
import {ErrorHandlerService} from '../shared/error-handler/error-handler.service';
// import {IntercomService} from '../shared/intercom/intercom.service';
import {LogOutService} from './log-out.service';
import {Listing} from '../models/listing';
import {Role} from '../models/role';
import {CookieService} from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  redirectUrl: string;
  user: User = new User();
  permissions: Permission[] = [];
  teams: Team[] = [];
  roles: Role[] = [];
  private retrievingCurrentUser: boolean = true;
  private $user: Subject<User> = new Subject();

  constructor(
    private http: HttpClient,
    // private intercomService: IntercomService,
    private userService: UserService,
    private logOut: LogOutService,
    private errors: ErrorHandlerService,
    private cookieService: CookieService,
    private router: Router,
  ) {
  }

  init() {
    if (!this.user.id && this.accessToken()) {
      this.retrievingCurrentUser = true;
      this.userService.current({includes: 'permissions,teams,roles'}).subscribe(
        user => this.setUpUser(user),
        error => null,
      );
    } else {
      this.retrievingCurrentUser = false;
    }
  }

  setUpUser(user: UserItem) {
    this.retrievingCurrentUser = false;
    this.user = user.data;
    if (user.included) {
      this.permissions = user.included.filter(include => include.type === 'permission');
      this.teams = user.included.filter(include => include.type === 'team');
      this.roles = user.included.filter(include => include.type === 'role');
      /*if (environment.name !== 'development') {
        const hash = user.included.find(include => include.type === 'hash');
        if (hash) {
          this.intercomService.shutdownAndReboot(hash['attributes']['hash'], user.data);
        }
      }*/
    }
    this.$user.next(this.user);
  }

  refreshToken(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = environment.vmc_api_url + 'refresh';
    return this.http.post(url, {headers: headers, withCredentials: true}).pipe(
      switchMap(response => {
        if (environment.env == 'dev') {
          localStorage.setItem('access_token', response['access_token']);
        } else {
          this.cookieService.set('accessToken' + '-' + environment.env, response['access_token'], 1, '/', environment.share_cookies_domain);
        }
        return of(response);
      }),
    );
  }

  login(email, password) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const url = environment.vmc_api_url + 'login?includes=permissions,teams,roles';
    const data = {email, password};
    return this.http.post(url, JSON.stringify(data), {headers: headers, withCredentials: true}).pipe(
      map(response => {
        localStorage.setItem('access_token', response['access_token']);
        localStorage.setItem('expires_in', response['expires_in']);
        localStorage.setItem('hash', response['hash']);
        this.setUpUser(response['user']);

        /*if (environment.name !== 'development') {
          this.intercomService.shutdownAndReboot(response['hash'], this.user);
        }*/

        Sentry.configureScope(scope => {
          scope.setUser({email: this.user.attributes.email});
        });

        return response;
      }),
      catchError(this.errors.dontReport([402, 401, 429]).handle('AuthenticationService::login', [], {
        url,
        data,
        method: 'POST',
        headers: this.getHeadersData(headers)
      })),
    );
  }
  protected getHeadersData (headers: HttpHeaders) {
    const keys = headers.keys();
    const headerData = {};
    keys.map(key => {
      headerData[key] = headers.get(key);
    });
    return headerData;
  }

  logout() {
    this.logOut.handle(this.redirectUrl);
    this.permissions = [];
    this.teams = [];
    this.roles = [];
  }

  isLoggedIn() {
    if (environment.env == 'dev') {
      return !!localStorage.getItem('access_token');
    } else {
      return this.cookieService.check('accessToken' + '-' + environment.env);
    }
  }

  sendResetLink(email): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const url = environment.vmc_api_url + 'password/email';
    // tslint:disable-next-line:max-line-length
    return this.http.post(url, JSON.stringify({email}), {headers}).pipe(catchError(this.errors.handle('AuthenticationService::sendResetLink, url=' + url + ', email=' + email, [])));
  }

  resetPassword(token, encryptedEmail, password, password_confirmation): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const body = JSON.stringify({
      token: token,
      encryptedEmail: encryptedEmail,
      password: password,
      password_confirmation: password_confirmation,
    });

    const url = environment.vmc_api_url + 'password/reset';
    return this.http.post(url, body, {headers}).pipe(
      catchError(
        this.errors.handle('AuthenticationService::resetPassword, url=' + url + ', email=' + encryptedEmail, []),
      ),
    );
  }

  can(permission, all = false): Observable<boolean> {
    return this.currentUser().pipe(
      map(() => {
        if (this.user.attributes.type == 'admin') {
          return true;
        }
        if (typeof permission == 'string') {
          return this.permissions.findIndex(item => item.attributes.name === permission) != -1;
        }
        if (Array.isArray(permission)) {
          const results = this.permissions.filter(value => {
            return permission.indexOf(value.attributes.name) != -1;
          }).length;

          return all ? results == permission.length : results > 0;
        }
        return false;
      }),
    );
  }

  hasRole(role, all = false): Observable<boolean> {
    return this.currentUser().pipe(
      map(() => {
        if (typeof role == 'string') {
          return this.roles.findIndex(item => item.attributes.name === role) != -1;
        }
        if (Array.isArray(role)) {
          const results = this.roles.filter(value => {
            return role.indexOf(value.attributes.name) != -1;
          }).length;

          return all ? results == role.length : results > 0;
        }
        return false;
      }),
    );
  }

  isMwu(): Observable<boolean> {
    return this.currentUser().pipe(
      map(() => Boolean(this.teams.find(team => team.attributes.branch_id == -1006))),
    );
  }

  isUserOfBranch(branch_id): Observable<boolean> {
    return this.currentUser().pipe(
      map(() => Boolean(this.teams.find(team => team.attributes.branch_id == branch_id))),
    );
  }

  branch_ids(): Array<any> {
    const branch_ids = [];
    this.teams.map(team => branch_ids.push(team.attributes.branch_id.toString()))
    return branch_ids;
  }

  canUpdateListing(listing: Listing): Observable<boolean> {
    let rt = false;
    let isUserOfBranch = false;
    let isUserTrustworthy = false;
    this.isUserOfBranch(listing.attributes.branch_id).subscribe(res => isUserOfBranch = res);
    this.can('user-trustworthy').subscribe(res => isUserTrustworthy = res);
    if (listing.attributes.users_count <= 0 || isUserOfBranch || isUserTrustworthy) {
      rt = true;
    }
    return of(Boolean(rt));
  }

  canUpdateMilestones(listing: Listing): Observable<boolean> {
    let canUpdateMilestone = false;
    let canUpdateListing = true;
    this.can('listing-update-milestones').subscribe(res => canUpdateMilestone = res);
    this.canUpdateListing(listing).subscribe(res => canUpdateListing = res  )
    return of(Boolean(canUpdateMilestone && canUpdateListing));
  }

  accessToken() {
    if (environment.env == 'dev') {
      return localStorage.getItem('access_token');
    } else {
      return this.cookieService.get('accessToken' + '-' + environment.env);
    }
  }

  currentUser(): Observable<User> {
    if (this.retrievingCurrentUser) {
      return this.$user.asObservable().pipe(first());
    }
    return of(this.user).pipe(first());
  }

  getRedirectPage() {
    let canViewMyProperties = false;
    let canViewMwuDashboard = false;
    let canReportViewConnells = false;
    let canClientRead = false;
    let canClientUserRead = false;
    let canViewConsumerForm = false;
    let canClientContactRead = false;
    this.permissionRedirect('view-my-properties').subscribe(can => { canViewMyProperties = can });
    this.permissionRedirect('view-mwu-dashboard').subscribe(can => (canViewMwuDashboard = can));
    this.permissionRedirect('report-view-connells').subscribe(can => (canReportViewConnells = can));
    this.permissionRedirect('client-read').subscribe(can => (canClientRead = can));
    this.permissionRedirect('client-user-read').subscribe(can => (canClientUserRead = can));
    this.permissionRedirect('view-consumer-form').subscribe(can => (canViewConsumerForm = can));
    this.permissionRedirect('client-contact-read').subscribe(can => (canClientContactRead = can));

    if (canViewMyProperties) {
      return window.location.href = environment.vmc_webapp2_url + 'listings';
    }
    if (canViewMwuDashboard) {
      return this.router.navigate(['/dashboard']);
    }
    if (canReportViewConnells) {
      return this.router.navigate(['/reports']);
    }
    if (canClientRead) {
      return this.router.navigate(['/clients']);
    }
    if (canClientUserRead) {
      return this.router.navigate(['/client-users']);
    }
    if (canViewConsumerForm) {
      return this.router.navigate(['/consumer/create']);
    }
    if (canClientContactRead) {
      return this.router.navigate(['/clients/contact-management']);
    }
    return this.router.navigate(['/profile'])
  }

  permissionRedirect(permission, all = false) {
    if (this.user.attributes.type == 'admin') {
      return of(true);
    }
  
    if (typeof permission == 'string') {
      return of(this.permissions.findIndex(item => item.attributes.name === permission) != -1);
    }
  
    if (Array.isArray(permission)) {
      const results = this.permissions.filter(value => {
        return permission.indexOf(value.attributes.name) != -1;
      }).length;
  
      return of(all ? results == permission.length : results > 0);
    }
    return of(false);
  }
}
