import {NgModule} from '@angular/core';
import {AddressSearchComponent} from './address-search.component';
import {CustomMaterialModule} from '../../custom-material/custom-material.module';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [
    CustomMaterialModule,
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [AddressSearchComponent],
  entryComponents: [AddressSearchComponent],
  providers: [],
  exports: [AddressSearchComponent],
})
export class AddressSearchModule {
}
