import { ContactService } from './../../api-services/contact.service';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ListingService} from '../../api-services/listing.service';

import {Router} from '@angular/router';
import {ListingItem} from '../../models/listing';

@Component({
  selector: 'vmc-add-an-address',
  templateUrl: './add-an-address.component.html',
  styleUrls: ['./add-an-address.component.scss'],
})
export class AddAnAddressComponent implements OnInit {
  public addressId;
  public full_address;
  public selected_address;
  public listing: ListingItem;
  public error_message: String;
  public individualId: number;
  public errors;

  public running: Boolean = false;

  constructor(
    @Inject(MatDialogRef) private dialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private listingService: ListingService,
    private contactService: ContactService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.listing = this.data.listing;
    this.individualId = this.data.individual_id;
  }

  setAddressId(address) {
    this.selected_address = address.attributes.full_address;
    this.addressId = address.id;
  }

  apply() {
    this.error_message = '';
    if (this.addressId) {
      this.running = true;
      this.updateIndividualAddress({
        'address_id': this.addressId,
        'individual_id': this.individualId,
        'listing_id': this.listing.data.id
      });
    }
  }

  resetAddress() {
    this.selected_address = '';
    this.addressId = null;
  }

  close() {
    this.dialog.close();
  }

  private updateIndividualAddress(data) {
    this.contactService.createIndividualAddress(data).subscribe(
      response => {
        if (response) {
          this.dialog.close(true);
        }
      },
      error => {
        this.error_message = error.error.message;
        this.errors = error.error.errors;
        this.running = false;
      },
    );
  }
}
