import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

import {catchError, retryWhen} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {RoleCollection} from '../models/role';

@Injectable()
export class RoleService extends ApiService {
  all(options): Observable<RoleCollection> {
    const url = this.url + 'role' + ApiService.httpBuildQuery(options);
    return this.http.get<RoleCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('RoleService::all, url=' + url, <RoleCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }
}
