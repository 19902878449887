import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError, retryWhen} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {EnvironmentalSearchProviderCollection, EnvironmentalSearchProviderItem} from '../models/environmental-search-provider';

@Injectable()
export class EnvironmentalSearchProviderService extends ApiService {
  get(id, options = {}): Observable<EnvironmentalSearchProviderItem> {
    const url = this.url + 'environmental_search_provider/' + id + ApiService.httpBuildQuery(options);
    return this.http.get<EnvironmentalSearchProviderItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(
        this.handleError(
          'EnvironmentalSearchProviderService::get, url=' + url + ', id=' + id,
          <EnvironmentalSearchProviderItem>{},
{
            url,
            method: 'GET',
            headers: this.getHeadersData(),
          },
        ),
      ),
    );
  }

  all(options = {}): Observable<EnvironmentalSearchProviderCollection> {
    const url = this.url + 'environmental_search_provider' + ApiService.httpBuildQuery(options);
    return this.http.get<EnvironmentalSearchProviderCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(
        this.handleError(
          'EnvironmentalSearchProviderService::all, url=' + url,
          <EnvironmentalSearchProviderCollection>{},
    {
            url,
            method: 'GET',
            headers: this.getHeadersData(),
          },
        ),
      ),
    );
  }
}
