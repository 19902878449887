import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError, retryWhen} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {SimpleResponse} from './api-response';
import {MWUClientMortgageCollection} from '../models/MWUClientMortgage';

@Injectable()
export class ClientMortgagesService extends ApiService {
  properties(client, options = {}): Observable<MWUClientMortgageCollection> {
    const url = this.url + 'reports/client-mortgages/properties/' + client + ApiService.httpBuildQuery(options);
    return this.http.get<MWUClientMortgageCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError<MWUClientMortgageCollection>(
        'ClientMortgagesService::properties, url=' + url, <MWUClientMortgageCollection>{}, {
          url,
          method: 'GET',
          headers: this.getHeadersData(),
        })
      ),
    );
  }

  send(client, options = {}): Observable<SimpleResponse> {
    const url = this.url + 'reports/client-mortgages/send/' + client + ApiService.httpBuildQuery(options);
    return this.http.get<SimpleResponse>(url, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError<SimpleResponse>('ClientMortgagesService::send, url=' + url, <SimpleResponse>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      }))
    );
  }

  getUrl(client, options = {}): Observable<any> {
    const url = this.url + 'reports/client-mortgages/get-url/' + client + ApiService.httpBuildQuery(options);
    return this.http.get(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ClientMortgagesService::getUrl, url=' + url, {}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  getDownloadUrl(client, options = {}): Observable<any> {
    const url = this.url + 'reports/client-mortgages/get-download-url/' + client + ApiService.httpBuildQuery(options);
    return this.http.get(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ClientMortgagesService::getDownloadUrl, url=' + url, {}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }
}
