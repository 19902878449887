import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs/internal/Observable';
import {catchError} from 'rxjs/operators';
import {MilestoneChecklistItemItem} from '../models/milestone-checklist-item';

@Injectable()
export class MilestoneChecklistItemService extends ApiService {
  add(data): Observable<MilestoneChecklistItemItem> {
    const url = this.url + 'milestone_checklist_item';
    return this.http.post<MilestoneChecklistItemItem>(url, data, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError('MilestoneChecklistItemService::add, url=' + url, <MilestoneChecklistItemItem>{})),
    );
  }

  update(id, data): Observable<MilestoneChecklistItemItem> {
    const url = this.url + 'milestone_checklist_item';
    return this.http.put<MilestoneChecklistItemItem>(url + `/${id}`, data, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError('MilestoneChecklistItemService::update, url=' + url, <MilestoneChecklistItemItem>{}),
      ),
    );
  }

  delete(id): Observable<MilestoneChecklistItemItem> {
    const url = this.url + 'milestone_checklist_item';
    return this.http.delete<MilestoneChecklistItemItem>(url + `/${id}`, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError(
          'MilestoneChecklistItemService::delete, url=' + url + ', data=' + id,
          <MilestoneChecklistItemItem>{},
        ),
      ),
    );
  }
}
