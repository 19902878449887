import {ApiResponse, Relation, Relations} from '../api-services/api-response';

export class ListingCollection extends ApiResponse {
  data: Listing[] = [];
}

export class ListingItem extends ApiResponse {
  data: Listing = new Listing();
}

export class ListingRelations {
  address: Relation;
  branch: Relation;
  buyer: Relation;
  seller: Relation;
  milestones: Relations;
  unconfirmed_milestones: Relations;
  contacts: Relations;
  changes: Relations;
  chain: Relations;
  notes: Relations;
  chain_data: Relation;
  milestone_states: Relation;
  meta: Relation;
  listing_update_queue: Relation;
}

export class Listing {
  id: number = 0;
  type: string = 'listing';
  attributes: ListingAttributes = new ListingAttributes();
  relationships?: ListingRelations;
}

export class ListingAttributes {
  address_id: number = 0;
  branch_id: number = 0;
  price: number = 0;
  chain_link_id: string = '';
  full_address: string = '';
  address_street_name: string = '';
  address_area: string = '';
  address_postal_code: string = '';
  created_at: number = 0;
  updated_at: number = 0;
  deleted_at: number = 0;
  verified_as_property: number = 0;
  branch_owner_id: number = 0;
  branch_name: '';
  estimated_exchange?: number = 0;
  exchange?: number = 0;
  users_count?: number = 0;
  difference?: string = '';
  extref?: Array<string> = [];
  land_new_home: boolean = false;

  are_possible_fall_through: boolean = false;
  confirm_fall_through_message: string = '';
  in_split_chain?: number = 0
  is_split_chain: boolean
  is_has_sstc: boolean
}
