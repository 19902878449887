import {Injectable} from '@angular/core';

@Injectable()
export class LinkService {
  constructor() {
  }

  public isPage(link) {
    return link.sysMeta && link.sysMeta.contentType === 'page';
  }

  public isMenuItem(link) {
    return link.sysMeta && link.sysMeta.contentType === 'menu';
  }

  public getLinkUrl(link) {
    if (this.isPage(link)) {
      return '/' + link.slug;
    }
    if (this.isMenuItem(link)) {
      return link.menuUrl;
    }
    if (typeof link === 'string') {
      return link;
    }
    return '/';
  }

  public getLinkName(link) {
    if (this.isPage(link)) {
      return link.pageTitle;
    }
    if (this.isMenuItem(link)) {
      return link.menuName;
    }
    return '';
  }

  public isExternalLink(link) {
    let linkUrl = link;
    if (link && link.menuUrl) {
      linkUrl = link.menuUrl;
    }
    return /^(http|www)/g.test(linkUrl);
  }

  public processMenuItem(menuItem, className = null) {
    menuItem.menuUrl = this.getLinkUrl(menuItem);
    menuItem.menuName = this.getLinkName(menuItem);
    menuItem.menuId = menuItem.menuName.replace(/ +/g, '-').toLowerCase();
    menuItem.menuItemClass = className ? className : menuItem.menuItemClass;
    return menuItem;
  }
}
