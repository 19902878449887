import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CustomMaterialModule} from '../custom-material/custom-material.module';
import {AuthenticationService} from './authentication.service';
import {LoginComponent} from './login/login.component';
import {LogOutService} from './log-out.service';
import {PermissionMapService} from './permission-map.service';
import {RefreshService} from './refresh.service';
import {CanDeactivateGuardService} from './guards/can-deactivate-guard.service';
import {AgentLoggedInGuard} from './guards/agent-logged-in.guard';
import {ConsumerLoggedInGuard} from './guards/consumer-logged-in.guard';
import {LoggedOutGuard} from './guards/logged-out.guard';
import {PermissionGuard} from './guards/permission.guard';
import {AuthRoutingModule} from './auth-routing.module';
// import {IntercomModule} from '../shared/intercom/intercom.module';
import {ProxyLoggedInGuard} from './guards/proxy-logged-in.guard';

@NgModule({
  imports: [CommonModule, FormsModule, CustomMaterialModule, AuthRoutingModule, ReactiveFormsModule],
  declarations: [LoginComponent],
  providers: [
    AuthenticationService,
    LogOutService,
    PermissionMapService,
    RefreshService,
    AgentLoggedInGuard,
    CanDeactivateGuardService,
    ConsumerLoggedInGuard,
    LoggedOutGuard,
    PermissionGuard,
  ],
})
export class AuthModule {
  static forRoot() {
    return {
      ngModule: AuthModule,
      providers: [
        AuthenticationService,
        LogOutService,
        PermissionMapService,
        RefreshService,
        AgentLoggedInGuard,
        CanDeactivateGuardService,
        ConsumerLoggedInGuard,
        LoggedOutGuard,
        PermissionGuard,
        ProxyLoggedInGuard,
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import it in the AppModule only');
    }
  }
}
