import {NgModule} from '@angular/core';
import {AddressFinderComponent} from './address-finder.component';
import {ReactiveFormsModule} from '@angular/forms';
import {AddressLookupModule} from '../address-lookup/address-lookup.module';
import {AddressSearchModule} from '../address-search/address-search.module';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [
    ReactiveFormsModule,
    AddressLookupModule,
    AddressSearchModule,
    CommonModule
  ],
  declarations: [AddressFinderComponent],
  entryComponents: [AddressFinderComponent],
  providers: [],
  exports: [AddressFinderComponent],
})
export class AddressFinderModule {
}
