import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';

@Injectable()
export class IsContentService {
  private updateNoteSource = new Subject<boolean>();

  isContent$ = this.updateNoteSource.asObservable();

  announceIsContent(data) {
    this.updateNoteSource.next(data);
  }
}
