import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vmc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public running: boolean = false;
  public message: string = '';
  public success: boolean = true;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.running = true;
    return window.location.href = environment.vmc_webapp2_url + 'login';
    // this.message = '';
    // this.setRedirectIfMissing();
    // this.authenticationService.login(email, password).subscribe(
    //   result => {
    //     if (result) {
    //       if (this.authenticationService.redirectUrl
    //         && this.authenticationService.redirectUrl != '/login'
    //         && this.authenticationService.redirectUrl != '/handle-logout') {
    //         this.router.navigate([this.authenticationService.redirectUrl]);
    //       } else {
    //         this.authenticationService.currentUser().subscribe(
    //           user => {
    //             switch (user.attributes.type.toLowerCase()) {
    //               case 'consumer':
    //                 return this.router.navigate(['home-mover'])
    //               case 'client_user':
    //                 return this.router.navigate(['users/home'])
    //               default:
    //                 return this.authenticationService.getRedirectPage()
    //             }
    //           },
    //           error => null,
    //         );
    //       }
    //     }
    //   },
    //   error => {
    //     this.running = false;
    //     let msg = 'We\'ve experienced an unexpected error, please try again.';
    //     if (error.error && error.error.status && error.error.status === 401) {
    //         msg = 'Please enter a correct email and password. Passwords are case-sensitive';
    //     }
    //     if (error.status === 422) {
    //       msg = 'Your account has no permission on ViewMyChain Web Application';
    //     }

    //     this.message = msg
    //     this.success = false;
    //   },
    // );
  }

  // onSubmit(email, password) {
  //   this.running = true;
  //   this.message = '';
  //   this.setRedirectIfMissing();
  //   this.authenticationService.login(email, password).subscribe(
  //     result => {
  //       if (result) {
  //         if (this.authenticationService.redirectUrl
  //           && this.authenticationService.redirectUrl != '/login'
  //           && this.authenticationService.redirectUrl != '/handle-logout') {
  //           this.router.navigate([this.authenticationService.redirectUrl]);
  //         } else {
  //           this.authenticationService.currentUser().subscribe(
  //             user => {
  //               switch (user.attributes.type.toLowerCase()) {
  //                 case 'consumer':
  //                   return this.router.navigate(['home-mover'])
  //                 case 'client_user':
  //                   return this.router.navigate(['users/home'])
  //                 default:
  //                   return this.router.navigate([this.authenticationService.getRedirectPage()])
  //               }
  //             },
  //             error => null,
  //           );
  //         }
  //       }
  //     },
  //     error => {
  //       this.running = false;
  //       let msg = 'We\'ve experienced an unexpected error, please try again.';
  //       if (error.error && error.error.status && error.error.status === 401) {
  //           msg = 'Please enter a correct email and password. Passwords are case-sensitive';
  //       }
  //       if (error.status === 422) {
  //         msg = 'Your account has no permission on ViewMyChain Web Application';
  //       }

  //       this.message = msg
  //       this.success = false;
  //     },
  //   );
  // }

  // private setRedirectIfMissing() {
  //   const redirectUrl = this.route.snapshot.queryParams.redirectUrl;

  //   if (redirectUrl && !this.authenticationService.redirectUrl) {
  //     this.authenticationService.redirectUrl = redirectUrl;
  //   }
  // }
}
