import {Injectable, Injector} from '@angular/core';
import Echo from 'laravel-echo';
import {environment} from '../../environments/environment';
import {AuthenticationService} from '../auth/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class EchoService {
  constructor(private injector: Injector) {
  }

  init() {
    ; (<any>window).Pusher = require('pusher-js');

    const authService = this.injector.get(AuthenticationService)

    ; (<any>window).Echo = new Echo({
      broadcaster: 'pusher',
      key: environment.pusher_app_key,
      cluster: environment.pusher_app_cluster,
      encrypted: environment.pusher_app_encrypted,
      authEndpoint: environment.vmc_root_url + 'broadcasting/auth',
      auth: {
        headers: {
          Authorization: 'Bearer ' + authService.accessToken(),
          Accept: 'application/json',
        },
      },
    });
  }
}
