import {ApiResponse, Relations} from '../api-services/api-response';

export class AddressCollection extends ApiResponse {
  data: Address[];
}

export class AddressItem extends ApiResponse {
  data: Address;
}

export class AddressRelations {
  listings: Relations;
}

export class Address {
  id: number = 0;
  type: string = 'address';
  attributes: AddressAttributes = new AddressAttributes();
  relationships: AddressRelations = new AddressRelations();
}

export class AddressAttributes {
  full_address: string = '';
  address_line_1: string = '';
  post_town: string = '';
  street: string = '';
  area: string = '';
  postcode: string = '';
  is_parent: boolean = false;
  created_at: number = 0;
  updated_at: number = 0;
}
