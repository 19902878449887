import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Header} from '../../../models/content/header';

@Component({
  selector: 'vmc-header-default',
  templateUrl: './header-default.component.html',
  styleUrls: ['./header-default.component.scss'],
})
export class HeaderDefaultComponent {
  @Output() public openMenuEmitter = new EventEmitter();
  @Output() public onMenuToggle = new EventEmitter();
  @Input() public isMenuOpened: boolean = false;
  @Input() public home_link = '/listings';
  @Input() public content: Header = new Header();

  toggleMenu() {
    this.onMenuToggle.emit();
  }

  reloadPage(supportLink: HTMLElement) {
    supportLink.blur();
    setTimeout(() => window.location.reload(), 100);
  }
}
