import {Injectable} from '@angular/core';

@Injectable()
export class PermissionMapService {
  public map = {
    '\\/address\\/create': 'address-create',
    '\\/listing\\/create': 'add-listing',
    '\\/attach-listing': 'attach-listing',
    '\\/clients\\/contact-management': 'client-contact-read',
    '\\/client-users': 'client-user-read',
    '\\/clients\\/create': 'client-create',
    '\\/clients\\/': 'client-update',
    '\\/clients': 'client-read',
    '\\/listing\\/[0-9]*\\/chain-management': 'listing-form-view',
    '\\/reports\\/branch-usage': 'view-branch-usage',
    '\\/reports\\/locked-listings-by-branch': 'view-locked-listing-by-branch',
    '\\/reports\\/chain-analysis-snapshot': 'view-chain-analysis-snapshot',
    '\\/reports\\/monthly-exchanges-fall-throughs': 'view-monthly-exchanges-fall-throughs',
    '\\/reports\\/engagement-report': 'view-branch-engagement-report',
    '\\/reports\\/law-firm-tracker': 'view-law-firm-tracker-report',
    '\\/reports\\/connells-panel-chained-transaction': 'view-connells-panel-chained-transaction-report',
    '\\/reports': 'report-view-connells',
    '\\/dashboard': 'view-mwu-dashboard',
    '\\/listings': 'view-my-properties',
    '\\/listing': 'view-my-properties',
    '\\/consumer\\/create': 'view-consumer-form',
    '\\/offers\\/[0-9]*\\/list': 'vmc-offer-read',
    '\\/offers\\/[0-9]*\\/create': 'vmc-offer-create',
    '\\/offers\\/[0-9]*\\/detail\\/[0-9]*': 'vmc-offer-read',
  };

  constructor() {
  }
}
