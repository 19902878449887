import {LoginComponent} from './login/login.component';
import {LoggedOutGuard} from './guards/logged-out.guard';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AgentLoggedInGuard} from './guards/agent-logged-in.guard';
import {LoginResolver} from './login/login-resolver.service';
import {ConsumerLoggedInGuard} from './guards/consumer-logged-in.guard';
import {PermissionGuard} from './guards/permission.guard';

const authRoutes: Routes = [
  {path: 'login', canActivate: [LoggedOutGuard], component: LoginComponent},
  {path: 'agents/sign_in', redirectTo: '/login', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
  providers: [LoginResolver, AgentLoggedInGuard, PermissionGuard, LoggedOutGuard, ConsumerLoggedInGuard],
})
export class AuthRoutingModule {
}
