import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError, retryWhen} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {PrivateSearchItem} from '../models/private-search';

@Injectable()
export class PrivateSearchesService extends ApiService {
  difference(listingId): Observable<PrivateSearchItem> {
    const url = this.url + 'listing/' + listingId + '/private-searches';
    return this.http.get<PrivateSearchItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError<PrivateSearchItem>('PrivateSearchesService::difference, url=' + url, <PrivateSearchItem>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }
}
