import {Injectable} from '@angular/core';
import {Resolve, Router} from '@angular/router';
import {AuthenticationService} from '../authentication.service';

@Injectable()
export class LoginResolver implements Resolve<any> {
  constructor(private router: Router, private auth: AuthenticationService) {
  }

  resolve(): void {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['/listings']);
      return null;
    }
  }
}
