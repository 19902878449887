import {Base} from './base';

export class Key extends Base {
  keyEntryTitle: string = '';
  keyTitle: string = '';
  keyBody: string = '';

  constructor(data = null) {
    super(data);
    this.keyEntryTitle = this.propertyIsValid(data, 'keyEntryTitle') ? data.keyEntryTitle : '';
    this.keyTitle = this.propertyIsValid(data, 'keyTitle') ? data.keyTitle : '';
    this.keyBody = this.propertyIsValid(data, 'keyBody') ? data.keyBody : '';
  }
}
