import {Base} from './base';

export class MenuItem extends Base {
  menuName: string = '';
  menuUrl: string = '';
  menuItemClass: string = '';
  menuItemType: string = '';
  isExternal: boolean = false;

  constructor(data = null) {
    super(data);
    this.menuName = this.propertyIsValid(data, 'menuName') ? data.menuName : '';
    this.menuUrl = this.propertyIsValid(data, 'menuUrl') ? data.menuUrl : '';
    this.menuItemClass = this.propertyIsValid(data, 'menuItemClass') ? data.menuItemClass : '';
    this.menuItemType = this.propertyIsValid(data, 'menuItemType') ? data.menuItemType : '';
    this.isExternal = this.menuItemType == 'external_link';
  }
}
