import {Category} from './category';
import {Author} from './author';
import {Base, Collection} from './base';
import {Block} from './block';
import {Collection as CollectionInterface} from './collection';

export class News extends Base implements Block {
  newsTitle: string = '';
  slug: string = '';
  newsBlurb: string = '';
  newsBody: string = '';
  newsThumbnailImage: string = '';
  newsImage: string = '';
  category: Category = new Category();
  tags: string = '';
  modelType: string = '';
  author: Author = new Author();
  sectionType: string = '';
  metaData: Object;

  constructor(data = null) {
    super(data);

    this.newsTitle = this.propertyIsValid(data, 'newsTitle') ? data.newsTitle : '';
    this.slug = this.propertyIsValid(data, 'slug') ? data.slug : '';
    this.newsBlurb = this.propertyIsValid(data, 'newsBlurb') ? data.newsBlurb : '';
    this.newsBody = this.propertyIsValid(data, 'newsBody') ? data.newsBody : '';
    this.newsThumbnailImage = this.propertyIsValid(data, 'newsThumbnailImage') ? data.newsThumbnailImage : '';
    this.newsImage = this.propertyIsValid(data, 'newsImage') ? data.newsImage : '';
    this.category = this.propertyIsValid(data, 'category', 'object') ? new Category(data.category) : new Category();
    this.tags = this.propertyIsValid(data, 'tags') ? data.tags : '';
    this.modelType = this.propertyIsValid(data, 'modelType') ? data.modelType : '';
    this.author = this.propertyIsValid(data, 'author', 'object') ? new Author(data.author) : new Author();
    this.sectionType = this.propertyIsValid(data, 'sectionType') ? data.sectionType : '';
    this.metaData = this.propertyIsValid(data, 'metaData', 'object') ? data.metaData : {};
  }
}

export class NewsCollection extends Collection implements CollectionInterface {
  data: News[] = [];

  constructor(data = null) {
    super(data);
    this.data = this.dataHasKey(data, 'data') ? data.data.map(item => new News(item)) : [];
  }
}
