import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AuthenticationService} from '../../../auth/authentication.service';
import {MatDialog} from '@angular/material';
import {ConfirmComponent} from '../../../dialogs/confirm/confirm.component';
import {Header} from '../../../models/content/header';
import {User} from '../../../models/user';

@Component({
  selector: 'vmc-header-consumer',
  templateUrl: './header-consumer.component.html',
  styleUrls: ['./header-consumer.component.scss'],
})
export class HeaderConsumerComponent implements OnInit, OnDestroy {
  @Input() public content: Header = new Header();
  @Input() public currentUser: User = new User();
  public home_link = '/home-mover';
  @Input() public isMenuOpened: boolean = false;
  @Input() public showBrochure = false;

  @Output() onContactUs = new EventEmitter();
  @Output() onMenuToggle = new EventEmitter();

  public openUserMenu = false;

  constructor(private auth: AuthenticationService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    document.body.addEventListener('mouseup', event => (this.openUserMenu = false), true);
  }

  ngOnDestroy() {
    document.body.removeEventListener('mouseup', event => (this.openUserMenu = false), true);
  }

  toggleMenu() {
    this.onMenuToggle.emit();
  }

  logout() {
    const modal = this.dialog.open(ConfirmComponent, {data: {question: 'Are you sure you want to log out?'}});
    modal.afterClosed().subscribe(result => {
      if (result) {
        this.auth.logout();
      }
    });
  }

  toggleUserMenu() {
    this.openUserMenu = !this.openUserMenu;
  }

  showContact() {
    this.onContactUs.emit();
  }
}
