import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'vmc-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  question: String = 'Are you sure?';
  confirmButtonText: String = 'Confirm';
  cancelButtonText: String = 'Cancel';
  onConfirm: () => {};
  onCancel: () => {};

  constructor(public dialogRef: MatDialogRef<ConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.data.question) {
      this.question = this.data.question;
    }
    if (this.data.confirmButtonText) {
      this.confirmButtonText = this.data.confirmButtonText;
    }
    if (this.data.cancelButtonText) {
      this.cancelButtonText = this.data.cancelButtonText;
    }
    if (this.data.onConfirm && typeof this.data.onConfirm === 'function') {
      this.onConfirm = this.data.onConfirm;
    }
    if (this.data.onCancel && typeof this.data.onCancel === 'function') {
      this.onCancel = this.data.onCancel;
    }
  }
}
