export class ConsumerSignUpContent {
  title: string = 'Consumer Sign Up Options';
  saleStepName: string = 'Sale Property';
  saleQuestion: string = 'Are you selling?';
  saleAddressInstruction: string = 'Pick the address of the property you are selling';
  saleAddressIsCorrespondenceQuestion: string = 'Is this also your correspondence address?';
  correspondenceAddressInstruction: string = 'Pick your correspondence address';
  saleAgentQuestion: string = 'Do you know the agent selling your property?';
  saleAddressRequiredError: string = 'Please select the address of the property you are selling';
  purchaseStepName: string = 'Purchase Property';
  purchaseQuestion: string = 'Are you buying?';
  purchaseAddressInstruction: string = 'Pick the address of the property you are buying';
  purchaseAddressRequiredError: string = 'Please select the address of the property you are buying';
  purchaseAgentQuestion: string = 'Do you know the agent selling the property you are buying?';
  personalDetailsStepName: string = 'About You';
  correspondenceRequiredError: string = 'Please select your correspondence address';
  firstNameRequiredError: string = 'Please provide your first name';
  lastNameRequiredError: string = 'Please provide your last name';
  emailRequiredError: string = 'Please provide your email';
  emailInvalidError: string = 'This does not appear to be a valid email address';
  passwordHint: string = 'Your password should be at least 8 characters';
  passwordRequiredError: string = 'Please provide a password';
  passwordMinLengthError: string = 'Your password should be at least 8 characters';
  passwordConfirmationRequiredError: string = 'Please confirm your password';
  passwordConfirmationMinLengthError: string = 'Your password should be at least 8 characters';
  passwordConfirmationConfirmedError: string = 'Your password doesn\'t match';
  recaptchaRequiredError: string = 'Are you human?';
  marketingConsentBody: string =
    'There are many products and services that people in the moving journey most commonly need. ' +
    'Part of what View My Chain offers is to provide information to you on these products and services at the point that we have' +
    ' identified that most people need them to make the home buying experience the best it can be.';
  marketingConsentQuestion: string = 'Please send me more information by post and email';
  termsAndConditionsBody: string =
    'By continuing the registration, you agree to our ' +
    '<a href="/terms-conditions" target="_blank">Terms and Conditions</a> and understand our ' +
    '<a href="/privacy-policy" target="_blank">Privacy Policy</a> which includes your permission for us to collect and display the ' +
    'status of your property transaction which we obtain from estate agents, property searches and registers of land.\n \n ' +
    'View My Chain will process the data that you provide us and will augment the data with geographic and demographic data about ' +
    'your household to identify if you are likely to have the requirements to know about a particular product or service and also when ' +
    'you are likely to have these requirements. The only reason we do this is to do our best to ensure that the information you have ' +
    'sent is relevant to you.\n \n If you do NOT want us to process your data in this way, please contact us to establish how you can ' +
    'be exempt from data processing.\n \n WARNING: IT IS UNLAWFUL TO IMPERSONATE SOMEONE ELSE. VIEW MY CHAIN CAN AND WILL TAKE ALL ' +
    'NECESSARY ACTION TO REMEDY ANY DAMAGE CAUSED BY ANYONE WHO IMPERSONATES TO BE SOMEONE ELSE. BY AGREEING TO OUR TERMS AND CONDITIONS ' +
    'YOU CONFIRM THAT YOU HAVE AUTHORITY TO VIEW THE PROPERTY CHAINS CREATED.';
  fundingStepName: string = 'Funding Consent';
  fundingConsentBody: string =
    'By ticking this box you consent to View My Chain Limited sourcing information from lenders and credit bureaus, such as Experian, ' +
    'to confirm the dates of your mortgage applications and for those organisations ' +
    'to return the mortgage application data they hold about you. <a href="https://info.viewmychain.com/experian-faq?hs_preview=MfwlBlqD-75240045261" target="_blank">Learn more</a>' +
    'This information is used solely to provide Estate agents, Conveyancers, Mortgage Advisors and others in your chain ' +
    'with a sales progression aide by reporting the progress of your mortgage application in relation to your property purchase. ' +
    'We will not access other personal, financial or mortgage related information and will not share this information ' +
    'with any third party not related to your property transaction.';
  fundingConsentSubtitle: string = 'This will not affect your credit rating. No credit checks will be made.';
  fundingConsentQuestion: string =
    'By ticking this box I give my consent for View My Chain to use information obtained from lenders, ' +
    'Experian or other credit bureaus relating to my mortgage application for the legitimate purpose of the View My Chain sales ' +
    'progression side.';
  agentFundingConsentStatement: string =
    'When accepting this on behalf of a home-mover, the following statement must be read verbatim, ' +
    'and you must be able to provide proof they have accepted this statement if called upon:';
  agentMarketingConsentStatement: string =
    'When accepting this on behalf of a home-mover, the following statement must be read ' +
    'verbatim, and you must be able to provide proof they have accepted this statement if called upon: "There are many products and ' +
    'services that people in the moving journey most commonly need. Part of what View My Chain offers is to provide information to you ' +
    'on these products and services at the point that we have identified that most people need them to make the home buying experience ' +
    'the best it can be."';
  sysMeta = {
    createdAt: '2018-06-01T08:09:59.531Z',
    updatedAt: '2018-06-01T08:09:59.531Z',
    contentType: 'consumerSignUp',
  };

  constructor(data = {}) {
    for (const key in data) {
      if (typeof data[key] == 'string' && this.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }
}
