import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError} from 'rxjs/operators';
import {ListingMetaItem} from '../models/listing-meta';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class ListingMetaService extends ApiService {
  create(data): Observable<ListingMetaItem> {
    const url = this.url + 'listing_meta';
    return this.http.post<ListingMetaItem>(url, data, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError<ListingMetaItem>('ListingMetaService::create, url=' + url, <ListingMetaItem>{})),
    );
  }

  update(id, data): Observable<ListingMetaItem> {
    const url = this.url + 'listing_meta/' + id;
    return this.http.put<ListingMetaItem>(url, data, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError<ListingMetaItem>('ListingMetaService::update, url=' + url, <ListingMetaItem>{})),
    );
  }

  sites(filters = {}): Observable<any> {
    const url = this.url + 'listing_meta/sites' + ApiService.httpBuildQuery(filters);
    return this.http.get(url, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError<any>('ListingMetaService::sites, url=' + url + ' filters=' + JSON.stringify(filters), <any>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  plots(filters = {}): Observable<any> {
    const url = this.url + 'listing_meta/plots' + ApiService.httpBuildQuery(filters);
    return this.http.get(url, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError<any>('ListingMetaService::plots, url=' + url + ' filters=' + JSON.stringify(filters), <any>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }
}
