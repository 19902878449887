import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError, retryWhen} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {ListingCollection} from '../models/listing';

@Injectable()
export class PossibleChainService extends ApiService {
  top(id): Observable<ListingCollection> {
    const url = this.url + 'listing/' + id + '/possible-chain/top';
    return this.http.get<ListingCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError<ListingCollection>('PossibleChainService::top, url=' + url, <ListingCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  bottom(id): Observable<ListingCollection> {
    const url = this.url + 'listing/' + id + '/possible-chain/bottom';
    return this.http.get<ListingCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError<ListingCollection>('PossibleChainService::bottom, url=' + url, <ListingCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }
}
