import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, concatMap} from 'rxjs/operators';
import {RefreshService} from './refresh.service';
import {AuthenticationService} from './authentication.service';
import {Observable} from 'rxjs/internal/Observable';
import {throwError} from 'rxjs/internal/observable/throwError';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authenticationService: AuthenticationService;

  private refreshService: RefreshService;

  constructor(private injector: Injector) {
  }

  addAuthHeader(request) {
    const authHeader = this.authenticationService.accessToken();
    if (authHeader) {
      return request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + authHeader,
        },
        withCredentials: true,
      });
    }
    return request;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.refreshService = this.injector.get(RefreshService);
    this.authenticationService = this.injector.get(AuthenticationService);
    request = this.addAuthHeader(request);

    return next.handle(request).pipe<HttpEvent<any>>(
      catchError(error => {
        if (error.status === 401) {
          // We don't want to refresh 401 login attempts
          if (error.url === environment.vmc_api_url + 'login?includes=permissions,teams,roles') {
            return throwError(error);
          }

          // The refreshToken api failure is also caught so we need to handle it here
          if (error.url === environment.vmc_api_url + 'refresh') {
            return this.refreshService.error(error);
          }

          return this.refreshService.handle().pipe(concatMap(() => next.handle(this.addAuthHeader(request))));
        }

        return throwError(error);
      }),
    );
  }
}
