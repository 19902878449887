import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError, retryWhen} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {ListingItem} from '../models/listing';

@Injectable()
export class PossibleLinksService extends ApiService {
  top(id): Observable<ListingItem> {
    const url = this.url + 'listing/' + id + '/possible-links/top';
    return this.http.get<ListingItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError<ListingItem>('PossibleLinksService::top, url=' + url, <ListingItem>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  dismissTop(id): Observable<ListingItem> {
    const url = this.url + 'listing/' + id + '/possible-links/dismiss-top';
    return this.http.get<ListingItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError<ListingItem>('PossibleLinksService::dismissTop, url=' + url, <ListingItem>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  bottom(id): Observable<ListingItem> {
    const url = this.url + 'listing/' + id + '/possible-links/bottom';
    return this.http.get<ListingItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError<ListingItem>('PossibleLinksService::bottom, url=' + url, <ListingItem>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }
}
