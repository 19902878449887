import {AuthenticationService} from './auth/authentication.service';
import {Component, OnInit} from '@angular/core';
import {EchoService} from './shared/echo.service';
import {environment} from '../environments/environment';
// import {IntercomService} from './shared/intercom/intercom.service';
import {Location, PopStateEvent} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {User} from './models/user';
import {UserService} from './api-services/user.service';
import * as Sentry from '@sentry/browser';
import {SwUpdate} from '@angular/service-worker';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'vmc-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isHeaderCanvas: Boolean = false;
  private lastPoppedUrl: string;
  public currentUser: User = new User();

  constructor(
    // private intercomService: IntercomService,
    private router: Router,
    private echo: EchoService,
    private location: Location,
    private auth: AuthenticationService,
    private userService: UserService,
    private updates: SwUpdate,
    private snackbar: MatSnackBar,
  ) {
    this.echo.init();
    if (environment.name !== 'development') {
      if (!this.auth.isLoggedIn()) {
        // this.intercomService.init();
      } else {
        this.auth.currentUser().subscribe(user => {
          if (!!user) {
            this.currentUser = user;
          }

          if (!!this.currentUser.attributes.email) {
            Sentry.configureScope(scope => {
              scope.setUser({email: this.currentUser.attributes.email});
            });
          }
        });
      }
    }
  }

  ngOnInit() {
    if (! window.location.pathname.includes('partner_redirect')) {
      this.auth.init();
    }
    this.location.subscribe((event: PopStateEvent) => {
      this.lastPoppedUrl = event.url;
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
        } else {
          window.scrollTo(0, 0);
        }
      }
    });

    this.updates.available.subscribe(event => {
      const snackbarRef = this.snackbar.open('A newer version of View My Chain is available', 'Update');
      snackbarRef.afterDismissed().subscribe(() => {
        this.updates.activateUpdate().then(() => window.location.reload());
      });
    });
  }

  handleHeaderMenu(canvasStatus) {
    this.isHeaderCanvas = canvasStatus;
    return this.isHeaderCanvas;
  }
}
