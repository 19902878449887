import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {ContentService} from '../content.service';
import {IsContentService} from '../is-content.service';
import {Hero} from '../../models/content/hero';
import {Page} from '../../models/content/page';
import * as json from '../../../assets/content/pages/not-found.json';

@Component({
  selector: 'vmc-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit, AfterViewChecked {
  content: Hero = new Hero();

  constructor(private contentService: ContentService, private isContent: IsContentService) {
  }

  ngOnInit() {
    const page = new Page(json['default']);
    this.content = new Hero(page.pageBlocks[0]);
  }

  ngAfterViewChecked(): void {
    this.isContent.announceIsContent(true);
  }
}
