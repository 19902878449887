import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError, retryWhen} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {MWUClientChainCollection} from '../models/MWUClientChain';
import {MWUClientChainDetailCollection} from '../models/MWUClientChainDetail';
import {SimpleResponse} from './api-response';

@Injectable()
export class ClientChainService extends ApiService {
  properties(client): Observable<MWUClientChainCollection> {
    const url = this.url + 'reports/client-chain/properties/' + client;
    return this.http.get<MWUClientChainCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError<MWUClientChainCollection>('ClientChainService::properties, url=' + url, <MWUClientChainCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  propertyDetails(id): Observable<MWUClientChainDetailCollection> {
    const url = this.url + 'reports/client-chain/properties/' + id + '/breakdown';
    return this.http.get<MWUClientChainDetailCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError<MWUClientChainDetailCollection>(
        'ClientChainService::propertyDetails, url=' + url,
        <MWUClientChainDetailCollection>{},
        {
          url,
          method: 'GET',
          headers: this.getHeadersData(),
        }
      )),
    );
  }

  getUrl(client): Observable<any> {
    const url = this.url + 'reports/client-chain/get-url/' + client;
    return this.http.get(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ClientChainService::getUrl, url=' + url, {}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  getDownloadUrl(client): Observable<any> {
    const url = this.url + 'reports/client-chain/get-download-url/' + client;
    return this.http.get(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ClientChainService::getDownloadUrl, url=' + url, {}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  send(client): Observable<SimpleResponse> {
    const url = this.url + 'reports/client-chain/send/' + client;
    return this.http.get<SimpleResponse>(url, {headers: this.getHeaders()}).pipe(catchError(this.handleError<SimpleResponse>('ClientChainService::send, url=' + url, <SimpleResponse>{}, {
      url,
      method: 'GET',
      headers: this.getHeadersData(),
    })));
  }
}
