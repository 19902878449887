import {Component, OnInit} from '@angular/core';
import {Footer} from '../../models/content/footer';
import * as json from '../../../assets/content/ids/14RitKkRh6MgmukQ644Emc.json';

@Component({
  selector: 'vmc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  content: Footer = new Footer();
  currentYear: number;

  constructor() {
  }

  ngOnInit(): void {
    this.content = new Footer(json['default']);
    this.currentYear = new Date().getFullYear();
  }
}
