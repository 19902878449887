import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {MilestoneGroupCollection, MilestoneGroupItem} from '../models/milestone-group';
import {ApiService} from './api.service';
import {catchError, retryWhen} from 'rxjs/operators';

@Injectable()
export class MilestoneGroupService extends ApiService {
  all(options = {}): Observable<MilestoneGroupCollection> {
    const url = this.url + 'milestone_group' + ApiService.httpBuildQuery(options);
    return this.http.get<MilestoneGroupCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('MilestoneGroupService::all, url=' + url, <MilestoneGroupCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  get(id, options = {}): Observable<MilestoneGroupItem> {
    const url = this.url + 'milestone_group/' + id + ApiService.httpBuildQuery(options);
    return this.http.get<MilestoneGroupItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('MilestoneGroupService::get, url=' + url, <MilestoneGroupItem>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }
}
