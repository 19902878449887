import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'vmc-confirm',
  templateUrl: './verify-listing.component.html',
  styleUrls: ['./verify-listing.component.scss'],
})
export class VerifyListingComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<VerifyListingComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }

  verify() {
    this.dialogRef.close(1);
  }

  remove() {
    this.dialogRef.close(0);
  }

  close() {
    this.dialogRef.close(-1);
  }

}
