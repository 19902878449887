import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {catchError, retryWhen} from 'rxjs/operators';
import {MilestoneDetailCollection} from '../models/milestone-detail';

@Injectable({
  providedIn: 'root',
})
export class ListingMilestoneDetailService extends ApiService {
  all(id): Observable<MilestoneDetailCollection> {
    const url = `${this.url}listing/${id}/milestone-detail`;
    return this.http.get<MilestoneDetailCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(
        this.handleError('ListingMilestoneDetailService::filter, url=' + url, <MilestoneDetailCollection>{}, {
          url,
          method: 'GET',
          headers: this.getHeadersData(),
        }),
      ),
    );
  }
}
