import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, Injectable, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import 'hammerjs';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthInterceptor} from './auth/auth.interceptor';
import {EchoInterceptor} from './shared/echo.interceptor';
import {AuthModule} from './auth/auth.module';
import {CriticalContentModule} from './critical-content/critical-content.module';
// import {IntercomModule} from './shared/intercom/intercom.module';
import {ApiServicesModule} from './api-services/api-services.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import * as Sentry from '@sentry/browser';
import { CookieService } from 'ngx-cookie-service';

// Raven
//   .config( environment.sentry_hook, { release: environment.build_version } )
//   .install();

function isErrorOrErrorEvent(wat) {
  return (
    Object.prototype.toString.call(wat) === '[object Error]' ||
    Object.prototype.toString.call(wat) === '[object ErrorEvent]'
  );
}

// export class RavenErrorHandler implements ErrorHandler {
//
//   handleError( err: any ): void {
//     const error = err.originalError || err.error || err;
//
//     if ( environment.name != 'development' ) {
//       const found = Object.prototype.toString.call( error ).match( '\[object\s?[a-zA-z]+\]' );
//
//       if ( found.length > 0 ) {
//         Raven.setExtraContext( error );
//       }
//
//       if ( isErrorOrErrorEvent( error ) ) {
//         Raven.captureException( error );
//       } else {
//         Raven.captureMessage( error.message || error );
//       }
//     } else {
//       console.error( error );
//       console.error( 'RavenErrorHandler: ', error.message );
//     }
//   }
//
// }

Sentry.init({
  dsn: environment.sentry_hook,
  release: environment.build_version,
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error) {
    console.log(error)
    if (environment.name != 'development') {
      const eventId = Sentry.captureException(error.originalError || error);
      // Sentry.showReportDialog( { eventId } );
      const found = Object.prototype.toString.call(error).match('[objects?[a-zA-z]+]');

      if (found.length > 0) {
        Sentry.configureScope(scope => {
          scope.setExtra('context', error);
        });
      }

      if (isErrorOrErrorEvent(error)) {
        Sentry.captureException(error);
      } else {
        Sentry.captureMessage(error.message || error);
      }
    } else {
      console.error(error);
      console.error('SentryErrorHandler: ', error.message);
    }
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AuthModule.forRoot(),
    CriticalContentModule,
    // IntercomModule,
    ApiServicesModule,
    // Must be after all other modules with route definitions due to the wild card route
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: EchoInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'en-GB'},
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    CookieService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
