import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, retryWhen} from 'rxjs/operators';
import {SearchPlatformCollection} from '../models/search-platform';
import {ApiService} from './api.service';

@Injectable()
export class SearchPlatformService extends ApiService {
  all(options = {}): Observable<SearchPlatformCollection> {
    const url = this.url + 'search-platform' + ApiService.httpBuildQuery(options);
    return this.http.get<SearchPlatformCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('SearchPlatformService::all, url=' + url, <SearchPlatformCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }
}
