import {Component, Input} from '@angular/core';
import {Cta} from '../../models/content/cta';

@Component({
  selector: 'vmc-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss'],
})
export class CtaComponent {
  @Input()
  cta: Cta = new Cta();

  activeModal: boolean;

  constructor() {
  }

  isInternalLink() {
    return this.cta.ctaType === 'internal_link';
  }

  isExternalLink() {
    return this.cta.ctaType === 'external_link';
  }

  isTelLink() {
    return this.cta.ctaType === 'tel_link';
  }

  isVideoTrigger() {
    return this.cta.ctaType === 'video';
  }

  toggleModal() {
    this.activeModal = !this.activeModal;
  }
}
