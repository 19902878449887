import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, retryWhen} from 'rxjs/operators';
import {ConveyancerCollection, ConveyancerItem} from '../models/conveyancer';

@Injectable()
export class ConveyancerService extends ApiService {
  all(options = {}): Observable<ConveyancerCollection> {
    const url = this.url + 'conveyancer' + ApiService.httpBuildQuery(options);
    return this.http.get<ConveyancerCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ConveyancerService::all, url=' + url, <ConveyancerCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  filter(filters, options = {}): Observable<ConveyancerCollection> {
    const url = this.url + 'conveyancer/filter' + ApiService.httpBuildQuery(options);
    return this.http.post<ConveyancerCollection>(url, filters, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(
        this.handleError(
          'ConveyancerService::filter, url=' + url + ', filters=' + JSON.stringify(filters),
          <ConveyancerCollection>{},
          {
            url,
            method: 'POST',
            headers: this.getHeadersData(),
            data: filters,
          }
        ),
      ),
    );
  }

  get(id): Observable<ConveyancerItem> {
    const url = this.url + 'conveyancer/' + id;
    return this.http.get<ConveyancerItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ConveyancerService::get, url=' + url, <ConveyancerItem>{})),
    );
  }
}
