export class Model {
  propertyIsValid(data, key, type = 'string') {
    return this.keyExists(data, key) && typeof data[key] === type;
  }

  keyExists(data, key) {
    return null !== data && typeof data === 'object' && data.hasOwnProperty(key);
  }

  dataHasKey(data, key: string) {
    return null !== data && typeof data === 'object' && data.hasOwnProperty(key) && Array.isArray(data[key]);
  }
}

export abstract class Base extends Model {
  sysMeta: BaseAttributes = new BaseAttributes();

  constructor(data = null) {
    super();
    this.sysMeta = this.propertyIsValid(data, 'sysMeta', 'object')
      ? new BaseAttributes(data.sysMeta)
      : new BaseAttributes();
  }
}

export class BaseAttributes extends Model {
  createdAt: string = '';
  updatedAt: string = '';
  contentType: string = '';

  constructor(data = null) {
    super();
    this.createdAt = this.propertyIsValid(data, 'createdAt') ? data.createdAt : '';
    this.updatedAt = this.propertyIsValid(data, 'updatedAt') ? data.updatedAt : '';
    this.contentType = this.propertyIsValid(data, 'contentType') ? data.contentType : '';
  }
}

export abstract class Collection extends Model {
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;

  constructor(data = null) {
    super();
    this.current_page = this.propertyIsValid(data, 'current_page', 'number') ? data.current_page : 0;
    this.first_page_url = this.propertyIsValid(data, 'first_page_url') ? data.first_page_url : '';
    this.from = this.propertyIsValid(data, 'from', 'number') ? data.from : 0;
    this.last_page = this.propertyIsValid(data, 'last_page', 'number') ? data.last_page : 0;
    this.last_page_url = this.propertyIsValid(data, 'last_page_url') ? data.last_page_url : '';
    this.next_page_url = this.propertyIsValid(data, 'next_page_url') ? data.next_page_url : '';
    this.path = this.propertyIsValid(data, 'path') ? data.path : '';
    this.per_page = this.propertyIsValid(data, 'per_page', 'number') ? data.per_page : 0;
    this.prev_page_url = this.propertyIsValid(data, 'prev_page_url') ? data.prev_page_url : '';
    this.to = this.propertyIsValid(data, 'to', 'number') ? data.to : 0;
    this.total = this.propertyIsValid(data, 'total', 'number') ? data.total : 0;
  }
}
