import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError, retryWhen} from 'rxjs/operators';
import {SimpleResponse} from './api-response';
import {Observable} from 'rxjs/internal/Observable';
import {ContactItem} from '../models/contact';
import {ReportCollection} from '../models/report';

@Injectable()
export class ContactService extends ApiService {
  attachToListing(listing, contact, role): Observable<SimpleResponse> {
    const url = `${this.url}contact/` + contact + '/listing/' + listing + '/attach';
    return this.http.post<SimpleResponse>(url, {role: role}, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError('ContactService::attachToListing, url=' + url + ', role=' + role, <SimpleResponse>{}),
      ),
    );
  }

  create(data): Observable<ContactItem> {
    const url = this.url + 'contact';
    return this.http.post<ContactItem>(url, data, {headers: this.getHeaders()}).pipe(catchError(this.handleError('ContactService::create, url=' + url, <ContactItem>{})));
  }

  update(id, data): Observable<ContactItem> {
    const url = this.url + 'contact/' + id;
    return this.http.put<ContactItem>(url, data, {headers: this.getHeaders()}).pipe(catchError(this.handleError('ContactService::update, url=' + url + ', id=' + id, <ContactItem>{})));
  }

  get(id): Observable<ContactItem> {
    const url = this.url + 'contact/' + id;
    return this.http.get<ContactItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ContactService::get, url=' + url + ', id=' + id, <ContactItem>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  reports(id): Observable<ReportCollection> {
    const url = this.url + 'contact/' + id + '/relationships/reports';
    return this.http.get<ReportCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ContactService::reports, url=' + url + ', id=' + id, <ReportCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  getIndividualByBranch(branchId) {
      const url = this.url + 'individual/get-by-branch/' + branchId;
      return this.http.get<any>(url, {headers: this.getHeaders()}).pipe(
        retryWhen(this.retryOnSystemFailure),
        catchError(this.handleError('ContactService::reports, url=' + url + ', id=' + branchId, {}, {
          url,
          method: 'GET',
          headers: this.getHeadersData(),
        })),
      );
  }

  getIndividualAddress(individualId) {
    const url = this.url + 'individual/' + individualId + '/individual-address';
      return this.http.get<any>(url, {headers: this.getHeaders()}).pipe(
        retryWhen(this.retryOnSystemFailure),
        catchError(this.handleError('ContactService::reports, url=' + url + ', id=' + individualId, {}, {
          url,
          method: 'GET',
          headers: this.getHeadersData(),
        })),
      );
  }

  createIndividualAddress(data) {
    const url = this.url + 'individual/' + data.individual_id + '/individual-address';
    return this.http.post<any>(url, data, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ContactService::individualAddress, url=' + url + ', id=' + data.individual_id)),
    );
  }

  getIndividual(id) {
    const url = this.url + 'individual/' + id;
    return this.http.get<any>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ContactService::individual, url=' + url + ', id=' + id, {}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
}



}
