import {Injectable} from '@angular/core';
import {Router, ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {PermissionMapService} from '../permission-map.service';
import {AuthenticationService} from '../authentication.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class PermissionGuard implements CanActivate, CanLoad {
  constructor(private permission: PermissionMapService, private auth: AuthenticationService,
    private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.hasPermissionFor(state.url);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean>|Promise<boolean>|boolean {
    return this.hasPermissionFor(route.path);
  }

  private hasPermissionFor(path: string) {
    let hasPermission;
    if (!this.auth.isLoggedIn()) {
      return false;
    }
    if (this.auth.user.attributes.type == 'admin') {
      return true;
    }
    const key = Object.keys(this.permission.map).find(regex => {
      return path.match(regex) !== null;
    });
    const permission = this.permission.map[key];
    if (typeof permission == 'undefined') {
      return true;
    }
    this.auth.can(permission).subscribe((can) => { hasPermission =  can});
    if (!hasPermission) {
      this.router.navigate(['not-found']);
    }
    return hasPermission;
  }
}
