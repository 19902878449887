import {LaggingListingCollection} from '../models/lagging-listing';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, retryWhen} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable()
export class LaggingListingService extends ApiService {
  filter(filters): Observable<LaggingListingCollection> {
    const url = `${this.url}lagging-listing/filter`;
    return this.http.post<LaggingListingCollection>(url, filters, {headers: this.getHeaders()}).pipe(
      debounceTime(300),
      distinctUntilChanged(),
      retryWhen(this.retryOnSystemFailure),
      catchError(
        this.handleError(
          'LaggingListingService::filter, url=' + url + ', filters=' + JSON.stringify(filters),
          <LaggingListingCollection>{},
          {
            url,
            method: 'POST',
            headers: this.getHeadersData(),
            data: filters,
          }
        ),
      ),
    );
  }
}
