import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, debounceTime, distinctUntilChanged, retryWhen} from 'rxjs/operators';
import {Listing, ListingCollection, ListingItem} from '../models/listing';
import {Milestone, MilestoneItem} from '../models/milestone';
import {UserCollection, UserItem} from '../models/user';
import {SimpleResponse} from './api-response';
import {ApiService} from './api.service';
import {BranchItem} from '../models/branch';
import {ChainDataItem} from '../models/chain-data';
import {ListingMetaCollection} from '../models/listing-meta';
import {NoteCollection, NoteItem} from '../models/note';
import {ListingMilestoneTimelineItem} from '../models/listing-milestone-timeline';
import {ConveyancerItem} from '../models/conveyancer';
import {PredictionCollection} from '../models/prediction';
import {ChainPredictionItem} from '../models/chain-prediction';
import { Offer } from '../models/offer';
import { OfferListing } from '../models/offer-listing';

@Injectable()
export class ListingService extends ApiService {
  filter(filters, options = {}): Observable<ListingCollection> {
    const url = `${this.url}listing/filter` + ApiService.httpBuildQuery(options);
    return this.http.post<ListingCollection>(url, filters, {headers: this.getHeaders()}).pipe(
      debounceTime(300),
      distinctUntilChanged(),
      retryWhen(this.retryOnSystemFailure),
      catchError(
        this.handleError('ListingService::filter, url=' + url + ', filters=' + JSON.stringify(filters), <ListingCollection>{}, {
          url,
          headers: this.getHeadersData(),
          data: filters,
          method: 'POST'
        })),
    );
  }

  exists(filters, options = {}): Observable<ListingCollection> {
    const url = `${this.url}listing/exists` + ApiService.httpBuildQuery(options);
    return this.http.post<ListingCollection>(url, filters, {headers: this.getHeaders()}).pipe(
      debounceTime(300),
      distinctUntilChanged(),
      retryWhen(this.retryOnSystemFailure),
      catchError(
        this.handleError('ListingService::exists, url=' + url + ',filters=' + JSON.stringify(filters), <ListingCollection>{},
          {
            url,
            headers: this.getHeadersData(),
            data: filters,
            method: 'POST',
          }))
    );
  }

  get(id: number, options = {}): Observable<ListingItem> {
    const url = `${this.url}listing/${id}` + ApiService.httpBuildQuery(options);
    return this.http.get<ListingItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::get, url=' + url, <ListingItem>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      }))
    );
  }

  getListingDetail(addressID: number, branchID: number): Observable<ListingItem> {
    const params = {
      address_id: addressID,
      branch_id: branchID
    }
    const headers = this.getHeaders();
    const url = `${this.url}get-listing-detail` + ApiService.httpBuildQuery(params);
    return this.http.get<ListingItem>(url, {headers}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::getListingDetail, url=' + url, <ListingItem>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  branch(id: number, options = {}): Observable<BranchItem> {
    const url = `${this.url}listing/${id}/relationships/branch` + ApiService.httpBuildQuery(options);
    return this.http.get<BranchItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::branch, url=' + url, <BranchItem>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  saleConveyancer(id: number, options = {}): Observable<ConveyancerItem> {
    const url = `${this.url}listing/${id}/relationships/sale-conveyancer` + ApiService.httpBuildQuery(options);
    return this.http.get<ConveyancerItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::saleConveyancer, url=' + url, <ConveyancerItem>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  chainData(id: number, options = {}): Observable<ChainDataItem> {
    const url = `${this.url}listing/${id}/relationships/chain-data` + ApiService.httpBuildQuery(options);
    return this.http.get<ChainDataItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::chainData, url=' + url, <ChainDataItem>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  add(data): Observable<ListingItem> {
    return this.http.post<ListingItem>(this.url + 'listing', data, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError('ListingService::add, url=' + this.url + 'listing, data=' + JSON.stringify(data), <ListingItem>{})),
    );
  }

  update(listing: Listing, attributes): Observable<ListingItem> {
    const url = `${this.url}listing/${listing.id}`;
    const data = this.prepListingForSubmission(listing, attributes);
    return this.http.put<ListingItem>(url, JSON.stringify(data), {headers: this.getHeaders()}).pipe(
      catchError(this.handleError('ListingService::update, url=' + url + ',data=' + JSON.stringify(data), <ListingItem>{})),
    );
  }

  updateMilestones(listing: Listing, data, options = {}): Observable<ListingItem> {
    const url = `${this.url}listing/${listing.id}/milestones` + ApiService.httpBuildQuery(options);
    return this.http.put<ListingItem>(url, data, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError('ListingService::updateMilestones, url=' + url + ', data=' + JSON.stringify(data), <ListingItem>{})),
    );
  }

  complete(listing: ListingItem, data): Observable<SimpleResponse> {
    const url = `${this.url}listing/${listing.data.id}/complete`;
    return this.http.put<SimpleResponse>(url, JSON.stringify(data), {headers: this.getHeaders()}).pipe(
      catchError(this.handleError('ListingService::complete, url=' + url + ',data=' + JSON.stringify(data), <SimpleResponse>{})),
    );
  }

  isComplete(id: number): Observable<SimpleResponse> {
    const url = `${this.url}listing/${id}/is-complete`;
    return this.http.get<SimpleResponse>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::isComplete, id=' + id, <SimpleResponse>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  lagging(id: number, options = {}): Observable<ListingCollection> {
    const url = `${this.url}listing/${id}/lagging` + ApiService.httpBuildQuery(options);
    return this.http.get<ListingCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::lagging, id=' + id, <ListingCollection>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  reopenEnquiries(id: number): Observable<SimpleResponse> {
    const url = `${this.url}listing/${id}/milestones/reopen-enquiries`;
    return this.http.put<SimpleResponse>(url, {}, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError('ListingService::reopenEnquiries, id=' + id, <SimpleResponse>{})),
    );
  }

  // TODO: rename
  salesProgressor(id: number, options = {}): Observable<UserItem> {
    const headers = this.getHeaders();
    const url = `${this.url}listing/${id}/sales-negotiator` + ApiService.httpBuildQuery(options);
    return this.http.get<UserItem>(url, {headers: headers}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::salesProgressor, id=' + id + ', url=' + url, <UserItem>{}, {
        url,
        headers,
        method: 'GET'
      })),
    );
  }

  fallThrough(id: number): Observable<SimpleResponse> {
    const headers = this.getHeaders();
    const url = `${this.url}listing/${id}/fall-through`;
    return this.http.put<SimpleResponse>(url, {}, {headers: headers}).pipe(
      catchError(this.handleError('ListingService::fallThrough, id=' + id, <SimpleResponse>{})),
    );
  }
  discardFallThrough(id: number): Observable<SimpleResponse> {
    const headers = this.getHeaders();
    const url = `${this.url}listing/${id}/discard-fall-through`;
    return this.http.put<SimpleResponse>(url, {}, {headers: headers}).pipe(
      catchError(this.handleError('ListingService::fallThrough, id=' + id, <SimpleResponse>{})),
    );
  }

  private prepListingForSubmission(listing: Listing, data) {
    const attributes = {
      address_id: listing.attributes.address_id,
      branch_id: listing.attributes.branch_id,
      price: listing.attributes.price,
    };
    return Object.assign(attributes, data);
  }

  groupState(listing: Listing): Observable<SimpleResponse> {
    const url = `${this.url}listing/${listing.id}/group-state`;
    return this.http.get<SimpleResponse>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::groupState, url=' + url, <SimpleResponse>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  offerGroupState(offerListingId): Observable<SimpleResponse> {
    const url = `${this.url}offers/offer-listing/${offerListingId}/group-state`;
    return this.http.get<SimpleResponse>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::groupState, url=' + url, <SimpleResponse>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  removeCashBuyer(listing: Listing): Observable<SimpleResponse> {
    const url = `${this.url}listing/${listing.id}/milestones/cash-buyer`;
    return this.http.delete<SimpleResponse>(url, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError('ListingService::removeCashBuyer, url=' + url, <SimpleResponse>{})),
    );
  }

  removeMilestone(listing: Listing, milestone: Milestone): Observable<SimpleResponse> {
    const url = `${this.url}listing/${listing.id}/milestone/${milestone.id}`;
    return this.http.delete<SimpleResponse>(url, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError('ListingService::destroyMilestone, url=' + url, <SimpleResponse>{})),
    );
  }

  removePossibleSearches(listing: Listing): Observable<SimpleResponse> {
    const url = `${this.url}listing/${listing.id}/milestones/possible-searches`;
    return this.http.delete<SimpleResponse>(url, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError('ListingService::removePossibleSearches, url=' + url, <SimpleResponse>{})),
    );
  }

  milestone(listing: Listing, milestone_type_key): Observable<MilestoneItem> {
    const url = `${this.url}listing/${listing.id}/milestone/${milestone_type_key}`;
    return this.http.get<MilestoneItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::milestone, url=' + url, <MilestoneItem>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  chainedListings(id: number): Observable<ListingCollection> {
    const url = `${this.url}listing/${id}/relationships/chained-listings`;
    return this.http.get<ListingCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::chainedListings, url=' + url, <ListingCollection>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  meta(id: number): Observable<ListingMetaCollection> {
    const url = `${this.url}listing/${id}/relationships/meta`;
    return this.http.get<ListingMetaCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::meta, url=' + url, <ListingMetaCollection>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  notes(id: number): Observable<NoteCollection> {
    const url = `${this.url}listing/${id}/relationships/notes`;
    return this.http.get<NoteCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::notes, url=' + url, <NoteCollection>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  note(listingId, noteId: number): Observable<NoteItem> {
    const url = `${this.url}listing/${listingId}/relationships/note/${noteId}`;
    return this.http.get<NoteItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::note, url=' + url, <NoteItem>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  milestoneTimeline(id: number): Observable<ListingMilestoneTimelineItem> {
    const url = `${this.url}listing/${id}/milestone-timeline`;
    return this.http.get<ListingMilestoneTimelineItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::milestoneTimeline, url=' + url, <ListingMilestoneTimelineItem>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  possibleSalesNegotiators(id: number): Observable<UserCollection> {
    const url = `${this.url}listing/${id}/possible-sales-negotiators`;
    return this.http.get<UserCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::possibleSalesNegotiators, url=' + url, <UserCollection>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  predictions(id: number): Observable<PredictionCollection> {
    const url = `${this.url}listing/${id}/predictions`;
    return this.http.get<PredictionCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::predictions, url=' + url, <PredictionCollection>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  chainPredictions(id: number): Observable<ChainPredictionItem> {
    const url = `${this.url}listing/${id}/chain-predictions`;
    return this.http.get<ChainPredictionItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::chainPredictions, url=' + url, <ChainPredictionItem>{}, {
        url,
        headers: this.getHeadersData(),
        method: 'GET'
      })),
    );
  }

  verifyListing(id: number): Observable<any> {
    const url = `${this.url}listing/${id}/verify`;
    return this.http.post<any>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::verifyListing, url=' + url, <any>{})),
    );
  }

  removeUnverifyListing(id: number): Observable<any> {
    const url = `${this.url}listing/${id}/remove-unverify`;
    return this.http.post<any>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ListingService::removeUnverifyListing, url=' + url, <any>{})),
    );
  }

}
