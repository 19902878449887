import {NgModule} from '@angular/core';
import {MarkdownComponent} from './markdown.component';
import {PipesModule} from '../pipes/pipes.module';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [PipesModule, CommonModule],
  declarations: [MarkdownComponent],
  exports: [MarkdownComponent],
})
export class MarkdownModule {
}
