import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocomplete, MatDialog} from '@angular/material';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, debounceTime, distinctUntilChanged, map, mergeMap, startWith} from 'rxjs/operators';
import {Address} from '../../models/address';
import {AddressCreateComponent} from '../../addresses/address-create/address-create.component';
import {AddressService} from '../../api-services/address.service';
import {AuthenticationService} from '../../auth/authentication.service';
import {of} from 'rxjs/internal/observable/of';
import {UnableToAddressMatchComponent} from '../../addresses/unable-to-address-match/unable-to-address-match.component';
import {ListingItem} from '../../models/listing';
import {ListingService} from '../../api-services/listing.service';
import {Router} from '@angular/router';

@Component({
  selector: 'vmc-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
})
export class AddressSearchComponent implements OnInit, AfterContentInit {
  myControl: FormControl = new FormControl();
  filteredOptions: Observable<Address[]>;

  @Input()
  public defaultValue: string = '';

  @Input()
  public rows: Number = 99;

  @Output()
  public onSelect = new EventEmitter();

  @Output()
  public onClose = new EventEmitter();

  @ViewChild('auto') auto: MatAutocomplete;

  @ViewChild('input') input: ElementRef;

  public createOptionLabel: String = 'Create new address';
  public unableToAddressMatchOptionLabel: String = 'Unable to address match';

  public displayCreateNewAddress: Boolean = false;
  public displayUnableToAddressMatch: Boolean = false;

  @Input()
  public displayCreateOption: Boolean = null;

  @Input()
  public id: string = 'default';

  @Input()
  public listing: ListingItem;

  running = false;

  private selectedAddress: Address = new Address();

  private mostRecentResults: Address[] = [];

  constructor(
    private addressService: AddressService,
    private auth: AuthenticationService,
    public dialog: MatDialog,
    private router: Router,
    private listingService: ListingService,
  ) {
  }

  ngOnInit() {
    this.setDisplayCreateOption();
    this.searchAddresses();

    this.myControl.setValue(this.defaultValue);
  }

  ngAfterContentInit(): void {
    if (this.auto.panel) {
      this.auto.showPanel = true;
      this.input.nativeElement.focus();
    }
  }

  select(address) {
    if (address.option.viewValue === this.createOptionLabel) {
      const dialogRef = this.dialog.open(AddressCreateComponent, {width: '800px'});
      dialogRef.afterClosed().subscribe(response => {
        if (response) {
          this.onSelect.emit(response['data']);
        }
      });
      return;
    }
    if (address.option.viewValue === this.unableToAddressMatchOptionLabel && this.listing) {
      if (this.listing.data.attributes.land_new_home) {
        const dialogRef = this.dialog.open(UnableToAddressMatchComponent, {
          width: '800px',
          data: {
            listing: this.listing
          }
        });
        dialogRef.afterClosed().subscribe(response => {
          if (response) {
            this.onSelect.emit(response['data']);
          }
        });
      } else {
        this.listingService.update(this.listing.data, {
          verified_as_property: -1
        }).subscribe(
          response => {
            this.onClose.emit();
            if (response.data.id) {
              this.router.navigate([`listing`]);
            }
          },
        );
      }
    }

    this.selectedAddress = address.option.value;
    this.onSelect.emit(address.option.value);
    this.running = false;
    return;

  }

  private searchAddresses() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(<string|Address>this.defaultValue),
      map(value => (typeof value === 'string' ? value : value.attributes.full_address)),
      debounceTime(300),
      distinctUntilChanged(),
      mergeMap(term => {
        if (term && term != this.selectedAddress.attributes.full_address) {
          this.running = true;
          return this.addressService.search({full_address: term}, {rows: this.rows}).pipe(
            map(result => {
              if (term === this.myControl.value) {
                this.mostRecentResults = result;
                this.running = false;
                return result;
              } else {
                return this.mostRecentResults;
              }
            }),
          );
        } else {
          this.running = false;
          return of([]);
        }
      }),
      catchError(error => of(<Address[]>[])),
    );
  }

  reset() {
    this.myControl.reset();
  }

  private setDisplayCreateOption() {
    if (this.displayCreateOption === null) {
      this.auth.can('address-create').subscribe(response => (this.displayCreateNewAddress = response));
      this.auth.can('address-create-integrator').subscribe(response => (this.displayUnableToAddressMatch = response));
    } else {
      this.displayCreateNewAddress = this.displayCreateOption;
      this.displayUnableToAddressMatch = this.displayCreateOption;
    }
  }

  display(address?: Address): string|undefined {
    return address ? address.attributes.full_address : undefined;
  }
}
