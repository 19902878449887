import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {Angulartics2Module} from 'angulartics2';
import {NotFoundComponent} from './critical-content/not-found/not-found.component';
import {AgentLoggedInGuard} from './auth/guards/agent-logged-in.guard';
import {LoggedOutGuard} from './auth/guards/logged-out.guard';
import {ProxyLoggedInGuard} from './auth/guards/proxy-logged-in.guard';
import {PermissionGuard} from './auth/guards/permission.guard';
import {MaintenanceComponent} from './critical-content/maintenance/maintenance.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'home',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'maintenance',
    redirectTo: '/login',
    pathMatch: 'full',
    // component: MaintenanceComponent,
  },
  {
    path: 'handle-logout',
    loadChildren: './logout-new-wapp/logout-new-wapp.module#LogoutNewWappModule',
    canLoad: [AgentLoggedInGuard],
  },
  {
    path: 'agent-live-demo',
    loadChildren: './agent-chain-builder-demo/agent-chain-builder-demo.module#AgentChainBuilderDemoModule',
    canLoad: [LoggedOutGuard],
  },
  {
    path: 'register',
    loadChildren: './self-sign-up/self-sign-up.module#SelfSignUpModule',
    canLoad: [LoggedOutGuard],
  },
  {
    path: 'profile',
    loadChildren: './users/users.module#UsersModule',
    canLoad: [AgentLoggedInGuard],
    pathMatch: 'full',
  },
  {
    path: 'users',
    loadChildren: './users/users.module#UsersModule',
    canLoad: [AgentLoggedInGuard, PermissionGuard],
  },
  {
    path: 'dashboard',
    loadChildren: './dashboards/dashboards.module#DashboardsModule',
    canLoad: [AgentLoggedInGuard],
  },
  {
    path: 'password',
    loadChildren: './auth/forgot-password/forgot-password.module#ForgotPasswordModule',
    canLoad: [LoggedOutGuard],
  },
  {
    path: 'branch',
    loadChildren: './teams/teams.module#TeamsModule',
    canLoad: [AgentLoggedInGuard],
  },
  {
    path: 'branches',
    loadChildren: './teams/teams.module#TeamsModule',
    canLoad: [AgentLoggedInGuard],
  },
  {
    path: 'clients',
    loadChildren: './clients/clients.module#ClientsModule',
    canLoad: [AgentLoggedInGuard],
  },
  {
    path: 'home-mover',
    loadChildren: './consumers/consumers.module#ConsumersModule',
  },
  {
    path: 'listing',
    loadChildren: './listings/listings.module#ListingsModule',
    canLoad: [AgentLoggedInGuard],
  },
  {
    path: 'listings',
    loadChildren: './listings/listings.module#ListingsModule',
    canLoad: [AgentLoggedInGuard],
  },
  {
    path: 'reports',
    loadChildren: './reports/reports.module#ReportsModule',
    canLoad: [AgentLoggedInGuard],
  },
  {
    path: 'agent',
    loadChildren: './listings/listings.module#ListingsModule',
    canLoad: [AgentLoggedInGuard],
  },
  {
    path: 'add/milestone/:token',
    loadChildren: './webhooks/webhooks.module#WebhooksModule',
  },
  {
    path: 'partner_redirect/:string',
    loadChildren: './sso/sso.module#SSOModule',
  },
  {path: 'not-found', component: NotFoundComponent},
  {
    path: 'proxy',
    loadChildren: './proxies/proxies.module#ProxiesModule',
    canLoad: [ProxyLoggedInGuard],
  },
  {
    path: 'consumer',
    loadChildren: './contacts/contacts.module#ContactsModule',
    canLoad: [AgentLoggedInGuard],
  },
  {
    path: 'client-users',
    loadChildren: './client-users/client-users.module#ClientUsersModule',
    canLoad: [AgentLoggedInGuard],
  },
  {
    path: 'offers',
    loadChildren: './offers/offers.module#OffersModule',
    canLoad: [AgentLoggedInGuard],
  },
  {
    path: 'tasks',
    loadChildren: './tasks/tasks.module#TasksModule',
    canLoad: [AgentLoggedInGuard],
  },
  {
    path: '',
    loadChildren: './content/content.module#ContentModule',
  },
  {path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}), Angulartics2Module.forRoot()],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
