import { Component, OnInit } from '@angular/core';
import {UserService} from '../../api-services/user.service';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'vmc-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  constructor(protected userService: UserService, protected router: Router, private cookieService: CookieService) { }

  ngOnInit() {
    // const accessToken = localStorage.getItem('access_token');
    const accessToken = this.cookieService.get('accessToken' + '-' + environment.env);
    if (accessToken) {
      localStorage.clear();
      this.cookieService.delete('accessToken' + '-' + environment.env, '/', environment.share_cookies_domain);
      window.location.reload();
    }
    // this.userService.maintenance().toPromise().then(res => {
    //   this.router.navigate(['/'])
    // })
  }

}
