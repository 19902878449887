import {Page} from './page';
import {MenuItem} from './menu-item';
import {Base} from './base';

export class Menu extends Base {
  menuEntryTitle: string = '';
  menuItems: MenuItem[]|Page[] = [];
  menuClass: string = '';

  constructor(data = null) {
    super(data);
    this.menuEntryTitle = this.propertyIsValid(data, 'menuEntryTitle', 'string') ? data.menuEntryTitle : '';
    this.menuClass = this.propertyIsValid(data, 'menuClass', 'string') ? data.menuClass : '';
    if (this.dataHasKey(data, 'menuItems')) {
      this.menuItems = data['menuItems'].map(item => {
        if (item.submenuOption) {
          const option = (item.submenuOption || []).find(val => {
            return val.env === 'dev';
          });
          if (option) {
            const fake = option.menuItem;

            if (fake.sysMeta && fake.sysMeta.contentType === 'menu') {
              fake.menuName = item.name;
            }
            if (fake.sysMeta && fake.sysMeta.contentType === 'page') {
              fake.pageTitle = item.name;
            }
            item = fake;
          }
        }
        if (item.sysMeta && item.sysMeta.contentType === 'menu') {
          return new MenuItem(item);
        }
        if (item.sysMeta && item.sysMeta.contentType === 'page') {
          return new Page(item);
        }
        return null;
      }).filter(x => x);
    } else {
      this.menuItems = [];
    }
  }
}
