export class ApiResponse {
  meta?: Meta;
  links?: Links;
  included?: any[];
}

export class Meta {
  pagination: Pagination = new Pagination();
}

export class Pagination {
  total: number = 0;
  count: number = 0;
  per_page: number = 15;
  current_page: number = 1;
  total_pages: number = 1;
}

export class Links {
  self: string;
  first: string;
  last: string;
  next?: string;
}

export class Relation {
  data: {
    id: number
    type: string
  };
}

export class Relations {
  data: {
    id: number
    type: string
  }[];
}

export class SimpleResponse {
  success: boolean = true;
  message: string = 'success';
  errors: string[] = [];
  data?: any[] = [];
}
