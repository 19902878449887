import {ApiResponse, Relation, Relations} from '../api-services/api-response';

export class UserCollection extends ApiResponse {
  data: User[] = [];
}

export class UserItem extends ApiResponse {
  data: User = new User();
}

export class UserRelations {
  contact: Relation;
  teams: Relations;
  roles: Relations;
  permissions: Relations;
  announcements: Relations;
  notifications: Relations;
}

export class User {
  id: number = null;
  type: string = '';
  attributes: UserAttributes = new UserAttributes();
  relationships?: UserRelations;
}

export class UserAttributes {
  first_name: string = '';
  last_name: string = '';
  email: string = '';
  type: string = '';
  contact_id: number = null;
  created_at: number = null;
  updated_at: number = null;
  deleted_at: number = null;
}
