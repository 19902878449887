import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError, map, retryWhen} from 'rxjs/operators';
import {TeamCollection} from '../models/team';
import {Branch, BranchCollection, BranchItem} from '../models/branch';
import {MilestoneTypeCollection} from '../models/milestone-type';
import {Observable} from 'rxjs/internal/Observable';
import {UserCollection} from '../models/user';

@Injectable()
export class BranchService extends ApiService {
  teams(options = {}): Observable<TeamCollection> {
    const url = this.url + 'teams' + ApiService.httpBuildQuery(options);
    return this.http.get<TeamCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('BranchService::teams, url=' + url, <TeamCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  get(id, options = {}): Observable<BranchItem> {
    const url = this.url + 'branch/' + id + ApiService.httpBuildQuery(options);
    return this.http.get<BranchItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('BranchService::get, url=' + url + ', id=' + id, <BranchItem>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  filter(filters, options = {}): Observable<Branch[]> {
    if (!filters.hasOwnProperty('rows')) {
      filters.rows = 1500;
    }

    const url = this.url + 'branch/filter' + ApiService.httpBuildQuery(options);
    return this.http.post<BranchCollection>(url, filters, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(
        this.handleError(
          'BranchService::filter, url=' + url + ', filters=' + JSON.stringify(filters),
          <BranchCollection>{},
          {
            url,
            method: 'POST',
            headers: this.getHeadersData(),
            data: filters,
          },
        ),
      ),
      map(response => response.data),
    );
  }

  workflow(id): Observable<MilestoneTypeCollection> {
    const url = this.url + 'branch/' + id + '/workflow';
    return this.http.get<MilestoneTypeCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('BranchService::workflow, url=' + url, <MilestoneTypeCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  users(id: number): Observable<UserCollection> {
    const url = this.url + 'branch/' + id + '/relationships/users';
    return this.http.get<UserCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('BranchService::users, url=' + url, <UserCollection>{},{
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  telephoneUpdate(id, attributes) {
    const url = this.url + 'branch/' + id + '/telephone';
    return this.http.put<BranchItem>(url, attributes, {headers: this.getHeaders()}).pipe(
      // retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('BranchService::users, url=' + url, <UserCollection>{})),
    );
  }
}
