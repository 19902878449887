import {Cta} from './cta';
import {Base} from './base';
import {Block} from './block';

export class Hero extends Base implements Block {
  heroEntryTitle: string = '';
  heroTitle: string = '';
  heroMessage: string = '';
  heroImage: string = '';
  heroCtAs: Cta[] = [];
  sectionType: string = '';

  constructor(data = null) {
    super(data);
    this.heroCtAs = this.dataHasKey(data, 'heroCtAs') ? data['heroCtAs'].map(item => new Cta(item)) : [];
    this.heroEntryTitle = this.propertyIsValid(data, 'heroEntryTitle') ? data.heroEntryTitle : '';
    this.heroTitle = this.propertyIsValid(data, 'heroTitle') ? data.heroTitle : '';
    this.heroMessage = this.propertyIsValid(data, 'heroMessage') ? data.heroMessage : '';
    this.heroImage = this.propertyIsValid(data, 'heroImage') ? data.heroImage : '';
    this.sectionType = this.propertyIsValid(data, 'sectionType') ? data.sectionType : '';
  }
}
