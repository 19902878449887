import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/authentication.service';

@Component({
  selector: 'vmc-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  showBanner: boolean = false;

  constructor(private auth: AuthenticationService, private router: Router) {
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      this.auth.currentUser().subscribe(user => {
        const userTypes = ['sales progressor', 'agent', 'branch manager', 'proxy', 'admin', 'individual conveyancer', 'company conveyancer']
        const isLogin = user.id
        this.showBanner = isLogin && userTypes.includes(user.attributes.type.toLowerCase())
      });
    })
  }
}
