import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {ListingNotificationCollection} from '../models/listing-notification';

@Injectable()
export class ListingNotificationService extends ApiService {
  get(id): Observable<ListingNotificationCollection> {
    const url = this.url + 'listing/' + id + '/notifications';
    return this.http.get<ListingNotificationCollection>(url, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError<ListingNotificationCollection>('ListingNotificationService::get, url=' + url, <ListingNotificationCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })));
  }
}
