import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {DelayCollection, DelayItem} from '../models/delay';

@Injectable()
export class DelayService extends ApiService {

  create(data): Observable<DelayItem> {
    const url = this.url + 'delay';
    return this.http.post<DelayItem>(url, data, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError<DelayItem>('DelayService::create, url=' + url + ', data=' + JSON.stringify(data))),
    );
  }

  deleteDelay(delayId): Observable<any> {
    const url = `${this.url}delay/${delayId}`;
    return this.http.delete(url, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError('DelayService::delete, url=' + url)),
    );
  }

  show(listingId, milestoneType): Observable<DelayItem> {
    const url = this.url + 'listing/' + listingId + '/milestone-type/' + milestoneType + '/delay';
    return this.http.get<DelayItem>(url, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError<DelayItem>('DelayService::show, url=' + url, <DelayItem>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  forListing(listingId): Observable<DelayCollection> {
    const url = this.url + 'listing/' + listingId + '/relationships/delays';
    return this.http.get<DelayCollection>(url, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError<DelayCollection>('DelayService::forListing, url=' + url, <DelayCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  chain(listingId: number): Observable<DelayCollection> {
    const url = this.url + 'delay/chain/' + listingId;
    return this.http.get<DelayCollection>(url, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError<DelayCollection>('DelayService::chain, url=' + url, <DelayCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }
}

