import {Base} from './base';
import {Block} from './block';

export class Page extends Base {
  pageTitle: string = '';
  pageBlocks: Block[] = [];
  pageTemplate: string = '';
  slug: string = '';

  constructor(data = null) {
    super(data);
    this.pageTitle = this.propertyIsValid(data, 'pageTitle') ? data.pageTitle : '';
    this.pageTemplate = this.propertyIsValid(data, 'pageTemplate') ? data.pageTemplate : '';
    this.slug = this.propertyIsValid(data, 'slug') ? data.slug : '';
    // the render service sorts the construction of these elements
    this.pageBlocks = this.dataHasKey(data, 'pageBlocks') ? data['pageBlocks'] : [];
  }
}
