import {NgModule} from '@angular/core';
import {AlertComponent} from './alert/alert.component';
import {ChainedConflictComponent} from './chained-conflict/chained-conflict.component';
import {ConfirmComponent} from './confirm/confirm.component';
import {FundingConsentComponent} from './funding-consent/funding-consent.component';
import {ConfirmWithDatepickerComponent} from './confirm-with-datepicker/confirm-with-datepicker.component';
import {VerifyListingComponent} from './verify-listing/verify-listing.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {CustomMaterialModule} from '../custom-material/custom-material.module';
import {MarkdownModule} from '../markdown/markdown.module';
import { AddAnAddressComponentModule } from './add-an-address/add-an-address.module';
import { ConfirmBreakChainComponent } from './confirm-break-chain/confirm-break-chain.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CustomMaterialModule,
    MarkdownModule,
    AddAnAddressComponentModule,
  ],
  declarations: [
    ConfirmComponent,
    FundingConsentComponent,
    ConfirmWithDatepickerComponent,
    AlertComponent,
    ChainedConflictComponent,
    VerifyListingComponent,
    ConfirmBreakChainComponent
  ],
  entryComponents: [
    ConfirmComponent,
    FundingConsentComponent,
    ConfirmWithDatepickerComponent,
    AlertComponent,
    ChainedConflictComponent,
    VerifyListingComponent,
    ConfirmBreakChainComponent
  ],
})
export class DialogsModule {
}
