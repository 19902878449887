import {NgModule} from '@angular/core';
import {SafePipe} from './safe.pipe';
import {SlugifyPipe} from './slugify.pipe';
import {TitleCasePipe} from './title-case.pipe';
import {MomentDatePipe} from './moment-date.pipe';

@NgModule({
  declarations: [SafePipe, SlugifyPipe, TitleCasePipe, MomentDatePipe],
  exports: [SafePipe, SlugifyPipe, TitleCasePipe, MomentDatePipe],
})
export class PipesModule {
}
