import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

import {Observable} from 'rxjs/internal/Observable';
import {catchError} from 'rxjs/operators';
import {RegistrationCollection} from '../models/registration';
import {SimpleResponse} from './api-response';

@Injectable()
export class RegistrationService extends ApiService {
  add(data, options = {}): Observable<RegistrationCollection> {
    const url = this.url + 'registration' + ApiService.httpBuildQuery(options);
    return this.http.post<RegistrationCollection>(url, data, {headers: this.getHeaders()}).pipe(catchError(this.handleError('RegistrationService::add, url=' + url, <RegistrationCollection>{})));
  }

  // TODO: Move to its own service
  register(data): Observable<SimpleResponse> {
    const url = this.url + 'register';
    return this.http.post<SimpleResponse>(url, data, {headers: this.getHeaders()}).pipe(catchError(this.handleError('RegistrationService::register, url=' + url, <SimpleResponse>{})));
  }
}
