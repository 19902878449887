import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs/internal/Observable';
import {catchError} from 'rxjs/operators';
import {SurveyCollection} from '../models/survey';

@Injectable()
export class SurveyService extends ApiService {
  loadSurveyData(milestoneGroupId, listingId): Observable<SurveyCollection> {
    const url = this.url + 'survey/milestone-group/' + milestoneGroupId + '/listing/' + listingId;
    // tslint:disable-next-line:max-line-length
    return this.http.get<SurveyCollection>(url, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError('SurveyService::loadSurveyData, url=' + url, <SurveyCollection>{},{
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })));
  }

  loadSurveyType(milestoneGroupId): Observable<any> {
    const url = this.url + 'survey-type/' + milestoneGroupId;
    // tslint:disable-next-line:max-line-length
    return this.http.get(url, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError('SurveyService::loadSurveyType, url=' + url, {},{
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })));
  }

  loadSurveyor(): Observable<any> {
    const url = this.url + 'surveyor';
    // tslint:disable-next-line:max-line-length
    return this.http.get(url, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError('SurveyService::loadSurveyor, url=' + url, {},{
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })));
  }

  createSurveyType(data): Observable<any> {
    const url = this.url + 'survey-type';
    // tslint:disable-next-line:max-line-length
    return this.http.post(url, data, {headers: this.getHeaders()}).pipe(catchError(this.handleError('SurveyService::createSurveyType, url=' + url, {})));
  }

  createSurveyor(data): Observable<any> {
    const url = this.url + 'surveyor';
    // tslint:disable-next-line:max-line-length
    return this.http.post(url, data, {headers: this.getHeaders()}).pipe(catchError(this.handleError('SurveyService::createSurveyor, url=' + url, {})));
  }

  createSurvey(id, data): Observable<any> {
    const url = this.url + 'survey/' + id;
    return this.http.post(url, {data: data}, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError(
          'SurveyService::createSurvey, url=' + url + ', id=' + id,
          [],
        ),
      ),
    );
  }

  updateSurvey(id, data): Observable<any> {
    const url = this.url + 'survey/' + id;
    return this.http.put(url, data, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError(
          'SurveyService::updateSurvey, url=' + url + ', id=' + id,
          [],
        ),
      ),
    );
  }

  deleteSurvey(id): Observable<any> {
    const url = this.url + 'survey/' + id;
    return this.http.delete(url, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError(
          'SurveyService::deleteSurvey, url=' + url + ', id=' + id,
          [],
        ),
      ),
    );
  }
}
