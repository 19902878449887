import {Pipe, PipeTransform} from '@angular/core';
import * as _moment from 'moment';

const moment = _moment;

@Pipe({
  name: 'momentDate',
})
export class MomentDatePipe implements PipeTransform {
  transform(value: any, format?: any): any {
    return value ? (typeof value == 'number' ? moment.unix(value).format(format ? format : 'DD-MM-YYYY') : moment(value).format(format ? format : 'DD-MM-YYYY')) : null;
  }
}
