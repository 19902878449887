import {Key} from './key';
import {Category} from './category';
import {Base, Collection} from './base';
import {Block} from './block';
import {Collection as CollectionInterface} from './collection';

export class CaseStudy extends Base implements Block {
  caseStudyTitle: string = '';
  slug: string = '';
  caseStudyImage: string = '';
  caseStudyExcerpt: string = '';
  caseStudyKeys: Key[] = [];
  caseStudyBody: string = '';
  caseStudyFile: string = '';
  category: Category = new Category();
  modelType: string = '';
  sectionType: string = '';

  constructor(data = null) {
    super(data);

    this.caseStudyTitle = this.propertyIsValid(data, 'caseStudyTitle') ? data.caseStudyTitle : '';
    this.slug = this.propertyIsValid(data, 'slug') ? data.slug : '';
    this.caseStudyImage = this.propertyIsValid(data, 'caseStudyImage') ? data.caseStudyImage : '';
    this.caseStudyExcerpt = this.propertyIsValid(data, 'caseStudyExcerpt') ? data.caseStudyExcerpt : '';
    this.caseStudyBody = this.propertyIsValid(data, 'caseStudyBody') ? data.caseStudyBody : '';
    this.caseStudyFile = this.propertyIsValid(data, 'caseStudyFile') ? data.caseStudyFile : '';
    this.category = this.propertyIsValid(data, 'category', 'object') ? new Category(data.category) : new Category();
    this.modelType = this.propertyIsValid(data, 'modelType') ? data.modelType : '';
    this.sectionType = this.propertyIsValid(data, 'sectionType') ? data.sectionType : '';
    this.caseStudyKeys = this.dataHasKey(data, 'caseStudyKeys') ? data['caseStudyKeys'].map(item => new Key(item)) : [];
  }
}

export class CaseStudyCollection extends Collection implements CollectionInterface {
  data: CaseStudy[] = [];

  constructor(data = null) {
    super(data);
    this.data = this.dataHasKey(data, 'data') ? data['data'].map(item => new CaseStudy(item)) : [];
  }
}
