import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../authentication.service';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable()
export class ConsumerLoggedInGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private auth: AuthenticationService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkLogin(state.url);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean>|Promise<boolean>|boolean {
    return this.checkLogin(state.url);
  }

  canLoad(route: Route): Observable<boolean>|Promise<boolean>|boolean {
    return this.checkLogin(`/${route.path}`);
  }

  private checkLogin(url = ''): Observable<boolean>|Promise<boolean>|boolean {
    this.auth.redirectUrl = url;

    if (this.auth.isLoggedIn()) {
      return this.auth.currentUser().pipe(
        map(user => this.checkUserType(user)),
        catchError(error => of(false)),
      );
    }

    this.router.navigate(['/login']);
    return false;
  }

  private checkUserType(user) {
    if (typeof user.attributes == 'undefined') {
      return false;
    }

    const userType = user.attributes.type.toLowerCase();

    const result = userType == 'admin' || userType == 'consumer';

    if (!result) {
      this.router.navigate(['/login']);
    }

    return result;
  }
}
