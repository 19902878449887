import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, retryWhen} from 'rxjs/operators';
import {LenderCollection} from '../models/lender';

@Injectable()
export class LenderService extends ApiService {
  all(options = {}): Observable<LenderCollection> {
    const url = this.url + 'lender' + ApiService.httpBuildQuery(options);
    return this.http.get<LenderCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('LenderService::all, url=' + url, <LenderCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }
}
