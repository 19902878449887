import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, map} from 'rxjs/operators';
import {User} from '../../models/user';
import {AuthenticationService} from '../authentication.service';
import {of} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AgentLoggedInGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private auth: AuthenticationService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean>|Promise<boolean>|boolean {
    return this.checkLogin(state.url);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean>|Promise<boolean>|boolean {
    return this.checkLogin(state.url);
  }

  private checkLogin(url = ''): Observable<boolean>|Promise<boolean>|boolean {
    this.auth.redirectUrl = url;

    if (this.auth.isLoggedIn()) {
      return this.auth.currentUser().pipe(
        map(user => this.checkUserType(user)),
        catchError(error => of(false)),
      );
    }
    this.router.navigate(['/login']);
    return false;
  }

  private checkUserType(user: User) {
    if (typeof user.attributes == 'undefined') {
      return false;
    }
    const userType = user.attributes.type.toLowerCase();

    const allowedUserTypes = [
      'admin',
      'agent',
      'proxy',
      'client_user',
      'branch manager',
      'sales progressor',
      'individual conveyancer',
      'company conveyancer',
      'individual consumer',
    ];
    const result = allowedUserTypes.includes(userType);

    if (!result) {
      this.router.navigate(['/handle-logout']);
    }

    return result;
  }

  canLoad(route: Route): Observable<boolean>|Promise<boolean>|boolean {
    this.auth.hasRole('Connells-role').subscribe(response => {
      if (`/${route.path}` == '/dashboard' && response == true) {
        this.router.navigate(['/listings']);
      }
    });
    return this.checkLogin(`/${route.path}`);
  }
}
