import {Menu} from './menu';
import {Cta} from './cta';
import {Base} from './base';

export class Header extends Base {
  headerEntryTitle: string = '';
  headerLogo: string = '';
  headerTopMenu: Menu = new Menu();
  headerTopCta: Cta = new Cta();
  headerMainCta: Cta = new Cta();
  headerMainMenu: Menu = new Menu();

  constructor(data = null) {
    super(data);
    this.headerEntryTitle = this.propertyIsValid(data, 'headerEntryTitle') ? data.headerEntryTitle : '';
    this.headerLogo = this.propertyIsValid(data, 'headerLogo') ? data.headerLogo : '';
    this.headerTopMenu = this.propertyIsValid(data, 'headerTopMenu', 'object')
      ? new Menu(data.headerTopMenu)
      : new Menu();
    this.headerTopCta = this.propertyIsValid(data, 'headerTopCta', 'object') ? new Cta(data.headerTopCta) : new Cta();
    this.headerMainCta = this.propertyIsValid(data, 'headerMainCta', 'object') ? new Cta(data.headerMainCta) : new Cta();
    this.headerMainMenu = this.propertyIsValid(data, 'headerMainMenu', 'object')
      ? new Menu(data.headerMainMenu)
      : new Menu();
  }
}
