import {Injectable} from '@angular/core';
import {ApiService} from '../api-services/api.service';
import {catchError, mergeMap, retryWhen} from 'rxjs/operators';
import {Page} from '../models/content/page';
import {CaseStudyCollection} from '../models/content/case-study';
import {NewsCollection} from '../models/content/news';
import {Block} from '../models/content/block';
import {Collection} from '../models/content/collection';
import {Observable} from 'rxjs/internal/Observable';
import {timer} from 'rxjs/internal/observable/timer';
import {range} from 'rxjs/internal/observable/range';
import {zip} from 'rxjs/internal/observable/zip';
import {throwError} from 'rxjs/internal/observable/throwError';

@Injectable()
export class ContentService extends ApiService {
  get(id) {
    const url = this.url + 'content/id/' + id;
    return this.http.get(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ContentService::get, url=' + url + ', id=' + id, [])),
    );
  }

  getPage(slug): Observable<Page> {
    const url = this.url + 'content/page/' + slug;
    return this.http.get<Page>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ContentService::getPage, url=' + url, new Page())),
    );
  }

  getPost(category, slug): Observable<Block> {
    const url = this.url + 'content/post/category/' + category + '/slug/' + slug;
    return this.http.get<Block>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ContentService::getPost, url=' + url, {sectionType: ''})),
    );
  }

  getNews(category, params = null): Observable<NewsCollection> {
    const url = this.url + 'content/post/type/' + category;
    return this.http.get<NewsCollection>(url, {headers: this.getHeaders(), params: params}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ContentService::getNews, url=' + url, new NewsCollection())),
    );
  }

  getPosts(category, params = null): Observable<Collection> {
    const url = this.url + 'content/post/type/' + category;
    return this.http.get<Collection>(url, {headers: this.getHeaders(), params: params}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ContentService::getPosts, url=' + url, {data: []})),
    );
  }

  getCaseStudies(category, params = null): Observable<CaseStudyCollection> {
    const url = this.url + 'content/post/type/' + category;
    return this.http.get<CaseStudyCollection>(url, {headers: this.getHeaders(), params: params}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ContentService::getCaseStudies, url=' + url, new CaseStudyCollection())),
    );
  }

  sendContact(contact) {
    const url = this.url + 'mail/contact';
    return this.http.post(url, contact, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError('ContentService::sendContact, url=' + url + ', contact=' + JSON.stringify(contact), []),
      ),
    );
  }

  protected retryOnSystemFailure(errors: Observable<Response>): Observable<any> {
    return zip(errors, range(1, 4)).pipe(mergeMap(([error, i]) => (i < 4 ? timer(300) : throwError(error))));
  }

  encrypt(toEncrypt) {
    const url = this.url + 'partner/encrypt';
    return this.http.post(url, toEncrypt, {headers: this.headers}).pipe(
      catchError(
        this.handleError('ContentService::testCon38Url, url=' + url + ', data=' + toEncrypt, []),
      ),
    );
  }
  testCon38Url(client_id, data) {
    const url = this.url + 'partner/' + client_id;
    return this.http.post(url, {data: data}, {headers: this.headers, withCredentials: true}).pipe(
      catchError(
        this.handleError('ContentService::testCon38Url, url=' + url + ', data=' + data, []),
      ),
    );
  }
}
