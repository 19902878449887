import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError, retryWhen} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {SimpleResponse} from './api-response';
import {MWUClientSearchCollection} from '../models/MWUClientSearch';

@Injectable()
export class ClientSearchesService extends ApiService {
  properties(client, options = {}): Observable<MWUClientSearchCollection> {
    const url = this.url + 'reports/client-searches/properties/' + client + ApiService.httpBuildQuery(options);
    return this.http.get<MWUClientSearchCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(
        this.handleError<MWUClientSearchCollection>('ClientSearchesService::properties, url=' + url, <MWUClientSearchCollection>{}, {
          url,
          method: 'GET',
          headers: this.getHeadersData(),
        })
      ),
    );
  }

  getUrl(client, options = {}): Observable<any> {
    const url = this.url + 'reports/client-searches/get-url/' + client + ApiService.httpBuildQuery(options);
    return this.http.get(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ClientSearchesService::getUrl, url=' + url, {} , {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  getDownloadUrl(client, options = {}): Observable<any> {
    const url = this.url + 'reports/client-searches/get-download-url/' + client + ApiService.httpBuildQuery(options);
    return this.http.get(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ClientSearchesService::getDownloadUrl, url=' + url, {}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  send(client, options = {}): Observable<SimpleResponse> {
    const url = this.url + 'reports/client-searches/send/' + client + ApiService.httpBuildQuery(options);
    return this.http.get<SimpleResponse>(url, {headers: this.getHeaders()}).pipe(
      catchError(this.handleError<SimpleResponse>('ClientSearchesService::send, url=' + url, <SimpleResponse>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      }))
    );
  }
}
