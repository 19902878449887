import {Injectable} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {AuthenticationService} from '../authentication.service';
import {Observable} from 'rxjs/internal/Observable';
import {Log} from '@angular/core/testing/src/logger';

@Injectable()
export class LoggedOutGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private auth: AuthenticationService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean>|Promise<boolean>|boolean {
    return this.isLoggedIn();
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean>|Promise<boolean>|boolean {
    return this.isLoggedIn();
  }

  canLoad(route: Route): Observable<boolean>|Promise<boolean>|boolean {
    return this.isLoggedIn();
  }

  private isLoggedIn() {
    if (this.auth.isLoggedIn()) {
      const params = new URLSearchParams(window.location.search);
      const redirectUrl = params.get('redirectUrl')
      console.log(redirectUrl)
      if (redirectUrl) {
        this.router.navigate([redirectUrl]);
      } else {
        this.router.navigate([this.auth.getRedirectPage()]);
      }
      return false;
    }

    return true;
  }
}
