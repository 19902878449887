import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {AlertComponent} from '../../dialogs/alert/alert.component';
// import {IntercomService} from '../intercom/intercom.service';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import * as Sentry from '@sentry/browser';
import {ProcessingParams} from '../../models/common';

@Injectable()
export class ErrorHandlerService {
  public dontReportStatusCodes = [422, 404, 403, 402, 401, 429];

  public dialogIsOpen: boolean = false;

  constructor(protected router: Router, protected dialog: MatDialog) {
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   * @param config
   */
  public handle<T>(operation = 'operation', result?: T, config?: ProcessingParams) {
    return (error: any): Observable<T> => {
      if (environment.name != 'production') {
        console.error('ErrorHandlerService: ', error);
        console.error('ErrorHandlerServiceOperation: ', operation);
      }

      if (error.status == 400) {
        this.handle400(error);
      }
      if (error.status == 402) {
        this.handle402(error);
      }
      if (error.status == 403) {
        this.handle403(error);
      }
      if (error.status == 0 || error.status == 503 ) {
        if (config) {
          this.handleApiDown(config);
        } else {
          this.genericErrorMessage();
        }
        // this.handleMaintenance();
      }
      if (error.status == 504) {
        if (config) {
          this.handleApiDown(config);
        } else {
          this.handleTimeout();
        }
      }
      if (error.status == 500) {
        this.genericErrorMessage();
      }

      if (this.shouldReport(error)) {
        this.report(operation, error);
      }

      // we want 422 errors to be returned into the error handler of the observables subscribe method
      // as this is likely form/validation errors
      if (error.status == 422) {
        throw error;
      }

      // we need to throw 404 errors as these will be handled within the component
      if (error.status == 404) {
        throw error;
      }

      // Let the app keep running by returning an error result.
      throw {
        error: {
          errors: [],
          status: error.status,
          message: 'Something went wrong. Status code: ' + error.status,
          success: false,
        },
      };
    };
  }

  dontReport(array) {
    const codes = this.dontReportStatusCodes.concat(array);
    this.dontReportStatusCodes = Array.from(new Set(codes));
    return this;
  }

  private isErrorOrErrorEvent(wat) {
    return (
      Object.prototype.toString.call(wat) === '[object Error]' ||
      Object.prototype.toString.call(wat) === '[object ErrorEvent]'
    );
  }

  private report(operation, error) {
    if (environment.name != 'development') {
      let message = `${operation} failed: ${error.message}`;
      if (error.error && error.error.message) {
        message += ' | ' + error.error.message;
      }

      const found = Object.prototype.toString.call(error).match('[objects?[a-zA-z]+]');

      if (found.length > 0) {
        // Raven.setExtraContext( error );
        Sentry.configureScope(scope => {
          scope.setExtra('context', error);
        });
      }

      if (this.isErrorOrErrorEvent(error)) {
        // Raven.captureException( error );
        Sentry.captureException(error);
      } else {
        // Raven.captureMessage( message );
        Sentry.captureMessage(message);
      }
    }
  }

  public shouldReport(error) {
    return this.dontReportStatusCodes.indexOf(error.status) === -1;
  }

  // todo maybe refactor to its own service if this class gets too big
  private handle400(error) {
    // todo redirect to a 400 page with more specific information on what they can do
    this.genericErrorMessage();
  }

  private handleTimeout() {
    this.showMessage(
      'Action timed out. Please check your internet connection and try again or contact support if the problem persists.',
    );
  }

  private handleApiDown(config: ProcessingParams) {
    if (!config.redirect_url) {
      config.redirect_url = window.location.href;
    }
    window.location.href = environment.vmc_webapp2_url + `processing?code=${this.base64EncodeUrl(config)}`;
  }

  private genericErrorMessage() {
    this.showMessage('Action failed. Please try again or contact support if the problem persists.');
  }

  private handle402(error) {
    this.showMessage(error.error.message);
    // this.intercom.show();
  }

  private handle403(error) {
    this.showMessage(
      'Your account permissions are not set up to perform this action. ' +
      'Please contact support to update your permissions.',
    );
    console.log('redirect login')
    this.router.navigate(['/login']);
  }

  private handleMaintenance() {
    this.router.navigate(['/maintenance']);
  }
  private showMessage(message: string) {
    if (!this.dialogIsOpen) {
      this.dialogIsOpen = true;
      const config = new MatDialogConfig();
      config.data = {message: message};
      const dialog = this.dialog.open(AlertComponent, config);
      dialog.afterClosed().subscribe(response => (this.dialogIsOpen = false));
    }
  }
  private base64EncodeUrl(param: any) {
    const data = JSON.stringify(param);
    const encodedUrl = encodeURIComponent(data);
    return btoa(encodedUrl);
  }

  private base64DecodeUrl(encodeData: string) {
    const decodedUrl = atob(encodeData);
    const url = decodeURIComponent(decodedUrl);
    return JSON.parse(url);
  }

}
