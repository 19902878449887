import {NgModule} from '@angular/core';
import {AddressLookupComponent} from './address-lookup.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {CustomMaterialModule} from '../../custom-material/custom-material.module';

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    CustomMaterialModule
  ],
  declarations: [AddressLookupComponent],
  entryComponents: [AddressLookupComponent],
  providers: [],
  exports: [AddressLookupComponent],
})
export class AddressLookupModule {
}
