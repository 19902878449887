import {Base} from './base';

export class Author extends Base {
  authorName: string = '';
  authorAvatar: string = '';

  constructor(data = null) {
    super(data);
    this.authorName = this.propertyIsValid(data, 'authorName') ? data.authorName : '';
    this.authorAvatar = this.propertyIsValid(data, 'authorAvatar') ? data.authorAvatar : '';
  }
}
