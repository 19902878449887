import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ErrorHandlerService} from '../shared/error-handler/error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {mergeMap} from 'rxjs/operators';
import {range} from 'rxjs/internal/observable/range';
import {zip} from 'rxjs/internal/observable/zip';
import {timer} from 'rxjs/internal/observable/timer';
import {Observable} from 'rxjs/internal/Observable';
import {throwError} from 'rxjs/internal/observable/throwError';
import {ProcessingParams} from '../models/common';

@Injectable()
export class ApiService {
  protected url = environment.vmc_api_url;

  protected headers = new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json');

  public static getAccessToken() {
    if (environment.env == 'dev') {
      return localStorage.getItem('access_token');
    } else {
      return this.getCookies('accessToken' + '-' + environment.env);
    }
  }

  protected static httpBuildQuery(params = {}) {
    if (typeof params === 'undefined' || typeof params !== 'object') {
      params = {};
      return params;
    }

    let query = '?';
    let index = 0;

    for (const i in params) {
      index++;
      const param = i;
      const value = params[i];
      if (index == 1) {
        query += param + '=' + value;
      } else {
        query += '&' + param + '=' + value;
      }
    }
    return query;
  }

  constructor(protected http: HttpClient, protected error: ErrorHandlerService) {
  }

  protected getHeaders() {
    const headers = this.headers;
    return headers.set('Authorization', 'Bearer ' + ApiService.getAccessToken());
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   * @param config
   */
  protected handleError<T>(operation = 'operation', result?: T, config?: ProcessingParams) {
    return this.error.handle(operation, result, config);
  }

  protected retryOnSystemFailure(errors: Observable<Response>): Observable<any> {
    return zip(errors, range(1, 4)).pipe(
      mergeMap(([error, i]) =>
        (error.status == 400 || error.status == 500) && i < 4 ? timer(300) : throwError(error),
      ),
    );
  }

  protected getHeadersData () {
    const headers = this.getHeaders()
    const keys = headers.keys();
    const headerData = {};
    keys.map(key => {
      headerData[key] = headers.get(key);
    });
    return headerData;
  }

  private static getCookies(cookieName: string): string {
    const name = cookieName + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
}
