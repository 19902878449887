import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, retryWhen} from 'rxjs/operators';
import {ClientUserCollection, ClientUserItem} from '../models/client-user';
import { SimpleResponse } from './api-response';

@Injectable({
  providedIn: 'root',
})
export class ClientUserService extends ApiService {
  dashboardAverages() {
    const url = this.url + 'client-user/dashboard/averages';
    return this.http.get(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ClientUserService::clientUserDashboardAverages, url=' + url, {}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  dashboardStatsByClient(options = {}) {
    const url = this.url + 'client-user/dashboard/clients' + ApiService.httpBuildQuery(options);
    return this.http.get(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ClientUserService::clientUserDashboardStatsByClient, url=' + url, {}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  crms() {
    const url = this.url + 'client-user/dashboard/crms';
    return this.http.get<SimpleResponse>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('ClientUserService::crms, url=' + url, {data: []}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  all(options = {}): Observable<ClientUserCollection> {
    const url = this.url + 'client-user' + ApiService.httpBuildQuery(options);
    return this.http.get<ClientUserCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError<ClientUserCollection>('ClientUserCollection::all, url=' + url, <ClientUserCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  get(id, options = {}): Observable<ClientUserItem> {
    const url = this.url + 'client-user/' + id + ApiService.httpBuildQuery(options);
    return this.http.get<ClientUserItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError<ClientUserItem>('ClientUserCollection::get, url=' + url)),
    );
  }

  update(id, data): Observable<ClientUserItem> {
    const url = this.url + 'client-user/' + id;
    return this.http.put<ClientUserItem>(url, data, {headers: this.getHeaders()}).pipe(catchError(this.handleError<ClientUserItem>('ClientUserService::update, url=' + url, <ClientUserItem>{})));
  }

  create(data): Observable<ClientUserItem> {
    const url = this.url + 'client-user';
    return this.http.post<ClientUserItem>(url, data, {headers: this.getHeaders()}).pipe(catchError(this.handleError<ClientUserItem>('ClientUserService::create, url=' + url, <ClientUserItem>{})));
  }

  delete(id): Observable<any> {
    const url = this.url + 'client-user/' + id;
    return this.http.delete(url, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError(
          'ClientUserService::delete, url=' + url + ', team_id=' + id,
          [],
        ),
      ),
    );
  }

  filter(filters, options = {}): Observable<ClientUserCollection> {
    const url = `${this.url}client-user/filter` + ApiService.httpBuildQuery(options);
    return this.http.post<ClientUserCollection>(url, filters, {headers: this.getHeaders()}).pipe(
      debounceTime(300),
      distinctUntilChanged(),
      retryWhen(this.retryOnSystemFailure),
      catchError(
        this.handleError(
          'ClientUserService::filter, url=' + url + ', filters=' + JSON.stringify(filters),
          <ClientUserCollection>{},
          {
            url,
            method: 'POST',
            headers: this.getHeadersData(),
            data: filters,
          }
        ),
      ),
    );
  }
}
