import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs/internal/Observable';
import {catchError} from 'rxjs/operators';
import {ProspectiveChainItem} from '../models/prospective-chain';

@Injectable()
export class ProspectiveChainService extends ApiService {
  add(data): Observable<ProspectiveChainItem> {
    return this.http.post<ProspectiveChainItem>(this.url + 'prospective-chain', data, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError(
          'ProspectiveChainService::add, url=' + this.url + 'prospective-chain, data=' + JSON.stringify(data),
          <ProspectiveChainItem>{},
        ),
      ),
    );
  }
}
