import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {LinkService} from '../link.service';
import {Menu} from '../../models/content/menu';
import {MenuItem} from '../../models/content/menu-item';

@Component({
  selector: 'vmc-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnChanges {
  @Input()
  menu: Menu = new Menu();

  @Input()
  itemClass = '';

  @Input()
  itemType = 'default';

  constructor(private linkService: LinkService) {
    //
  }

  ngOnInit() {
    this.setUp();
  }

  ngOnChanges() {
    this.setUp();
  }

  private setUp() {
    this.menu.menuItems = (this.menu.menuItems as MenuItem[]).map(item =>
      this.linkService.processMenuItem(item, this.itemClass),
    );
  }
}
