import {Base} from './base';
import {Page} from './page';

export class Cta extends Base {
  ctaEntryTitle: string = '';
  ctaType: string = '';
  ctaText: string = '';
  ctaPage: Page = new Page();
  ctaUrl: string = '';
  ctaClass: string = '';

  constructor(data = null) {
    super(data);

    this.ctaEntryTitle = this.propertyIsValid(data, 'ctaEntryTitle') ? data.ctaEntryTitle : '';
    this.ctaType = this.propertyIsValid(data, 'ctaType') ? data.ctaType : '';
    this.ctaText = this.propertyIsValid(data, 'ctaText') ? data.ctaText : '';
    this.ctaPage = this.propertyIsValid(data, 'ctaPage', 'object') ? new Page(data.ctaPage) : new Page();
    this.ctaUrl = this.propertyIsValid(data, 'ctaUrl') ? data.ctaUrl : '';
    this.ctaClass = this.propertyIsValid(data, 'ctaClass') ? data.ctaClass : '';
  }
}
