import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs/internal/Observable';
import {ListingMetaItem} from '../models/listing-meta';
import {catchError, retryWhen} from 'rxjs/operators';
import {LenderItem} from '../models/lender';
import {BrokerItem} from '../models/broker';

@Injectable({
  providedIn: 'root',
})
export class ListingListingMetaService extends ApiService {
  save(listingId, data, options?): Observable<ListingMetaItem> {
    const url = this.url + 'listing/' + listingId + '/listing-meta' + ApiService.httpBuildQuery(options);
    return this.http.post<ListingMetaItem>(url, data, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError<ListingMetaItem>('ListingListingMetaService::save, url=' + url, <ListingMetaItem>{}),
      ),
    );
  }

  get(listingId, options?): Observable<ListingMetaItem> {
    const url = this.url + 'listing/' + listingId + '/listing-meta' + ApiService.httpBuildQuery(options);
    return this.http.get<ListingMetaItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(
        this.handleError<ListingMetaItem>('ListingListingMetaService::get, url=' + url, <ListingMetaItem>{},{
          url,
          method: 'GET',
          headers: this.getHeadersData(),
        }),
      ),
    );
  }

  saveNewLender(data): Observable<LenderItem> {
    const url = this.url + 'lender';
    return this.http.post<LenderItem>(url, data, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError<LenderItem>('ListingListingMetaService::saveNewLender, url=' + url, <LenderItem>{}),
      ),
    );
  }

  saveNewBroker(data): Observable<BrokerItem> {
    const url = this.url + 'broker';
    return this.http.post<BrokerItem>(url, data, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError<BrokerItem>('ListingListingMetaService::saveNewBroker, url=' + url, <BrokerItem>{}),
      ),
    );
  }
}
