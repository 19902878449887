import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError, map, retryWhen} from 'rxjs/operators';
import {Brand, BrandCollection} from '../models/brand';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class BrandService extends ApiService {
  search(filters, options): Observable<Brand[]> {
    const url = this.url + 'brand/filter' + ApiService.httpBuildQuery(options);
    return this.http.post<BrandCollection>(url, filters, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(
        this.handleError(
          'BrandService::search, url=' + url + ', filters=' + JSON.stringify(filters),
          <BrandCollection>{},
          {
            url,
            method: 'POST',
            headers: this.getHeadersData(),
            data: filters,
          }
        ),
      ),
      map(response => response.data),
    );
  }
}
