import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { catchError } from "rxjs/operators";
import { SimpleResponse } from "./api-response";
import { Listing, ListingItem } from "../models/listing";
import { Observable } from "rxjs/internal/Observable";

@Injectable()
export class ListingChainService extends ApiService {
  checkTop(listing: Listing): Observable<SimpleResponse> {
    const url = this.url + "listing/chain/" + listing.id + "/check/top";
    return this.http
      .put<SimpleResponse>(url, {}, { headers: this.getHeaders() })
      .pipe(
        catchError(
          this.handleError(
            "ListingChainService::checkTop, url=" + url,
            <SimpleResponse>{}
          )
        )
      );
  }

  uncheckTop(listing: Listing): Observable<SimpleResponse> {
    const url = this.url + "listing/chain/" + listing.id + "/uncheck/top";
    return this.http
      .put<SimpleResponse>(url, {}, { headers: this.getHeaders() })
      .pipe(
        catchError(
          this.handleError(
            "ListingChainService::uncheckTop, url=" + url,
            <SimpleResponse>{}
          )
        )
      );
  }

  checkBottom(listing: Listing): Observable<SimpleResponse> {
    const url = this.url + "listing/chain/" + listing.id + "/check/bottom";
    return this.http
      .put<SimpleResponse>(url, {}, { headers: this.getHeaders() })
      .pipe(
        catchError(
          this.handleError(
            "ListingChainService::checkBottom, url=" + url,
            <SimpleResponse>{}
          )
        )
      );
  }

  uncheckBottom(listing: Listing): Observable<SimpleResponse> {
    const url = this.url + "listing/chain/" + listing.id + "/uncheck/bottom";
    return this.http
      .put<SimpleResponse>(url, {}, { headers: this.getHeaders() })
      .pipe(
        catchError(
          this.handleError(
            "ListingChainService::uncheckBottom, url=" + url,
            <SimpleResponse>{}
          )
        )
      );
  }

  addListingToChain(listing: Listing, data): Observable<ListingItem> {
    const url = this.url + "listing/chain/" + listing.id + "/add";
    return this.http
      .post<ListingItem>(url, data, { headers: this.getHeaders() })
      .pipe(
        catchError(
          this.handleError(
            "ListingChainService::addListingToChain, url=" + url,
            <ListingItem>{}
          )
        )
      );
  }

  addTop(listing: Listing, link: Listing): Observable<SimpleResponse> {
    const url =
      this.url + "listing/chain/" + listing.id + "/add/top/" + link.id;
    return this.http
      .put<SimpleResponse>(url, {}, { headers: this.getHeaders() })
      .pipe(
        catchError(
          this.handleError(
            "ListingChainService::addTop, url=" + url,
            <SimpleResponse>{}
          )
        )
      );
  }

  addBottom(listing: Listing, link: Listing): Observable<SimpleResponse> {
    const url =
      this.url + "listing/chain/" + listing.id + "/add/bottom/" + link.id;
    return this.http
      .put<SimpleResponse>(url, {}, { headers: this.getHeaders() })
      .pipe(
        catchError(
          this.handleError(
            "ListingChainService::addBottom, url=" + url,
            <SimpleResponse>{}
          )
        )
      );
  }

  chainMap(listing: Listing): Observable<SimpleResponse> {
    const url = this.url + "listing/chain/" + listing.id + "/chain-map/";

    return this.http
      .get<SimpleResponse>(url, { headers: this.getHeaders() })
      .pipe(
        catchError(
          this.handleError(
            "ListingChainService::chainMap, url=" + url,
            <SimpleResponse>{},
            {
              url,
              method: 'GET',
              headers: this.getHeadersData()
            }
          )
        )
      );
  }
}
