import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ErrorHandlerModule} from '../shared/error-handler/error-handler.module';
import {AddressService} from './address.service';
import {ApiService} from './api.service';
import {BranchService} from './branch.service';
import {BrandService} from './brand.service';
import {ChainService} from './chain.service';
import {ContactService} from './contact.service';
import {ExchangeReadyService} from './exchange-ready.service';
import {InvitationService} from './invitation.service';
import {LaggingListingService} from './lagging-listing.service';
import {ListingChainService} from './listing-chain.service';
import {ListingListingMetaService} from './listing-listing-meta.service';
import {ListingMetaService} from './listing-meta.service';
import {ListingService} from './listing.service';
import {MilestoneChecklistItemService} from './milestone-checklist-item.service';
import {MilestoneChecklistService} from './milestone-checklist.service';
import {MilestoneGroupBranchService} from './milestone-group-branch.service';
import {LenderService} from './lender.service';
import {BrokerService} from './broker.service';
import {EnvironmentalSearchProviderService} from './environmental-search-provider.service';
import {MilestoneGroupService} from './milestone-group.service';
import {ProspectiveChainService} from './prospective-chain.service';
import {RegistrationService} from './registration.service';
import {ReportService} from './report.service';
import {RoleService} from './role.service';
import {SearchPlatformService} from './search-platform.service';
import {TeamService} from './team.service';
import {UserService} from './user.service';
import {ConveyancerService} from './conveyancer.service';
import {ListingMilestoneDetailService} from './listing-milestone-detail.service';
import {ClientChainService} from './client-chain.service';
import {ClientSearchesService} from './client-searches.service';
import {ClientMortgagesService} from './client-mortgages.service';
import {PossibleLinksService} from './possible-links.service';
import {ListingNotificationService} from './listing-notification.service';
import {TeamMembersService} from './team-members.service';
import {OutstandingItemService} from './outstanding-item.service';
import {PossibleChainService} from './possible-chains.service';
import {PrivateSearchesService} from './private-searches.service';
import {DelayService} from './delay.service';
import {DelayTypeService} from './delay-type.service';
import {ClientUserService} from './client-user.service';
import {SurveyService} from './survey.service';
import {MilestoneReasonService} from './milestone-reason.service';

@NgModule({
  imports: [
    CommonModule,
    ErrorHandlerModule
  ],
  providers: [
    AddressService,
    ApiService,
    BranchService,
    BrandService,
    ChainService,
    ClientChainService,
    ContactService,
    ConveyancerService,
    DelayService,
    DelayTypeService,
    ExchangeReadyService,
    InvitationService,
    LaggingListingService,
    ListingChainService,
    ListingListingMetaService,
    ListingMetaService,
    ListingService,
    MilestoneChecklistItemService,
    MilestoneChecklistService,
    MilestoneGroupBranchService,
    ListingService,
    LaggingListingService,
    ListingListingMetaService,
    LenderService,
    BrokerService,
    EnvironmentalSearchProviderService,
    MilestoneGroupService,
    ProspectiveChainService,
    RegistrationService,
    ReportService,
    RoleService,
    SearchPlatformService,
    TeamService,
    UserService,
    ListingMilestoneDetailService,
    ClientSearchesService,
    ClientMortgagesService,
    PossibleLinksService,
    ListingNotificationService,
    TeamMembersService,
    OutstandingItemService,
    PossibleChainService,
    PrivateSearchesService,
    PossibleChainService,
    ClientUserService,
    SurveyService,
    MilestoneReasonService
  ]
})
export class ApiServicesModule {
}
